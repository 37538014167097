import React, { useState, useEffect, Fragment } from "react"
// import firebase from 'firebase'
import _ from 'lodash'
import config from '../config'
// import { Menu, Message, Icon, Grid, Button, Segment, Table, Modal, Image, Select, Dimmer, Loader } from 'semantic-ui-react'
import { Button, Form, Input, Grid, TextArea, Select } from 'semantic-ui-react'
import axios from 'axios'
// import moment from 'moment'

export default function Prospector(props) {
    const qs = (key) => {
        key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
        var match = window.location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    const [twilioSid, setTwilioSid] = useState(localStorage.getItem("twilioSid") || "")
    const [twilioToken, setTwilioToken] = useState(localStorage.getItem("twilioToken") || "")
    const [manychatApiKey, setManychatApiKey] = useState(localStorage.getItem("manychatApiKey") || "")
    const [flows, setFlows] = useState([])
    const [manychatFlowNs, setManychatFlowNs] = useState(localStorage.getItem("manychatFlowNs" || ""))
    const [To, setTo] = useState(qs('phone') || "")
    const [From, setFrom] = useState(localStorage.getItem("From") || "")
    const [FirstName, setFirstName] = useState(qs('first_name') || "")
    const [LastName, setLastName] = useState(qs('last_name') || "")
    const [Message, setMessage] = useState(qs('message') || "")
    const [isMobile, setIsMobile] = useState(null)

    useEffect(() => {
        if(isMobile !== null)
            return
        let phone = qs("phone") || false
        if(!!phone){
            phoneLookup(phone)
        }
    }, [])
    useEffect(() => {
        localStorage.setItem("twilioSid", twilioSid);
    }, [twilioSid])
    useEffect(() => {
        localStorage.setItem("twilioToken", twilioToken);
    }, [twilioToken])
    useEffect(() => {
        localStorage.setItem("manychatApiKey", manychatApiKey);
        getFlows()
    }, [manychatApiKey])
    useEffect(() => {
        localStorage.setItem("manychatFlowNs", manychatFlowNs)
    }, [manychatFlowNs])
    useEffect(() => {
        localStorage.setItem("To", To)
    }, [To])
    useEffect(() => {
        localStorage.setItem("From", From)
    }, [From])
    useEffect(() => {
        localStorage.setItem("Message", Message)
    }, [Message])

    const phoneLookup = async (phone) => {
        const url = `${config.API_URL}/api/twilio_slack/v2/twilioPhoneLookup`
        const response = await axios.post(url, {
            twilioSid,
            twilioToken,
            phone
        })
        // setIsMobile
        if(response.data.carrier.type === 'mobile')
            setIsMobile(true)
        else
            setIsMobile(false)
    }

    const getFlows = async () => {
        const manychatFlows = await axios.post(`${config.API_URL}/api/manychat/getFlows`, {
            manychatApiKey
        })
        const flows = []
        _.each(manychatFlows.data.data.flows, flow => {
            flows.push({
                key: flow.ns,
                value: flow.ns,
                text: flow.name
            })
        })
        setFlows(flows)
    }

    return (
        <div>
            <h1>Prospector</h1>
            <Form>
                <Grid stackable>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>Twilio SID</label>
                                <Input onChange={(e) => {
                                    setTwilioSid(e.target.value)
                                }} value={twilioSid} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Twilio Auth Token</label>
                                <Input onChange={(e) => {
                                    setTwilioToken(e.target.value)
                                }} value={twilioToken} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>Manychat API Key</label>
                                <Select options={[
                                    { key: "0", value: "100295241363197:743d4eb4fa523a73e9756948da9e0dc5", text: "Special Invitation", selected: true}
                                ]} 
                                value={manychatApiKey}
                                onChange={(event, data) => setManychatApiKey(data.value)} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Manychat Flow</label>
                                <Select options={flows} 
                                    value={manychatFlowNs}
                                    onChange={(event, data) => setManychatFlowNs(data.value)} 
                                />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>To Phone Number</label>
                                <Input onChange={(e) => {
                                    setTo(e.target.value)
                                }} value={To} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>From Twilio Number</label>
                                <Input onChange={(e) => {
                                    setFrom(e.target.value)
                                }} value={From} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form.Field>
                                <label>First Name</label>
                                <Input onChange={(e) => {
                                    setFirstName(e.target.value)
                                }} value={FirstName} />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field>
                                <label>Last Name</label>
                                <Input onChange={(e) => {
                                    setLastName(e.target.value)
                                }} value={LastName} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                        <Form.Field>
                                <label>Text Message on Successful Voicemail Drop</label>
                                <TextArea onChange={(e) => {
                                    setMessage(e.target.value)
                                }} value={Message} />
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button disabled={!isMobile} onClick={() => {
                                const url = `${config.API_URL}/api/twilio_slack/v2/vmdrop`
                                axios.post(url, {
                                    twilioSid,
                                    twilioToken,
                                    To,
                                    From,
                                    Message,
                                    manychatApiKey
                                })
                                .then(response => alert("done"))
                            }} positive icon='checkmark' labelPosition='right' content={isMobile === false ? 'Not Mobile' : 'Voicemail Drop'} />
                            <Button disabled onClick={() => {
                                const url = `${config.API_URL}/api/twilio_slack/v2/smsdrop`
                                axios.post(url, {
                                    twilioSid,
                                    twilioToken,
                                    To,
                                    From,
                                    Message
                                })
                                .then(response => console.log(response.data))
                            }} positive icon='checkmark' labelPosition='right' content='SMS Drop' />
                            <Button 
                                onClick={() => {
                                    const url = `${config.API_URL}/api/manychat/send_sms`
                                    axios.post(url, {
                                        twilioSid,
                                        twilioToken,
                                        To,
                                        From,
                                        Message,
                                        manychatApiKey,
                                        manychatFlowNs,
                                        FirstName,
                                        LastName
                                    })
                                    .then(response => {
                                        if(response.data.error !== undefined)
                                            alert(response.data.error)
                                    })
                                }} positive icon='checkmark' labelPosition='right' content={isMobile === false ? 'Not Mobile' : 'ManyChat SMS'}
                                disabled={!isMobile}
                            />

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <TextArea value={`$("bdi").each(function(){    
    var tr = $(this).closest("tr")
    var name = tr.find("a:eq(0)").html()
    var first_name = name !== undefined ? name.split(" ")[0] : ""
    var last_name = name !== undefined ? name.split(" ")[1] : ""
    var company = tr.find("td:eq(1)").html()
    var bdi = tr.find("bdi")
    var phone = bdi.html()
    if(phone !== undefined){
        phone = phone.replace( /\D+/g, '')
        if(phone[0] !== "1")
            phone = "+1"+phone
    }
    //var message = 'Hi '+first_name+', I came across a network of resorts who are letting businesses give away their unsold rooms for free. They\'re being used as sales and marketing incentives and getting huge results for local businesses. I figured you could use these to boost sales for '+company+'. Let me know and I can send the info. Thanks - Chad'
    var message = 'Hi '+first_name+', I wanted to connect with you because I have access to some free resort rooms that can be given away. I figured you could use these as incentives to boost sales (or as thank you gifts) for '+company+'. Let me know and I can send the info. Thanks - Chad'
    var url = 'https://chatbot.media/prospector?message='+message+'&phone='+phone+'&first_name='+first_name+'&last_name='+last_name
    bdi.html('<a href="'+url+'" target="_blank">'+phone+'</a>')
})`}
                            />    
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                
            </Form>
        </div>
    )
}

// $("bdi").each(function(){    
//     var tr = $(this).closest("tr")
//     var name = tr.find("a:eq(0)").html()
//     var first_name = name !== undefined ? name.split(" ")[0] : ""
//     var last_name = name !== undefined ? name.split(" ")[1] : ""
//     var company = tr.find("td:eq(1)").html()
//     var bdi = tr.find("bdi")
//     var phone = bdi.html()
//     var message = 'Hi '+first_name+', this is going to sound weird, but I need to fill some guest rooms at a resort nearby.  I figured you could use them as incentives to attract/close business for '+company+'. I can send more info if you say “OK”. Let me know. Thanks - Chad'
//     var url = 'https://chatbot.media/prospector?message='+message+'&phone='+phone+'&first_name='+first_name+'&last_name='+last_name
//     bdi.html('<a href="'+url+'" target="_blank">'+phone+'</a>')
// })
