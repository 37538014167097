import React, { useState, useEffect, Fragment } from "react"
import firebase from 'firebase'
import _ from 'lodash'
import { Radio, Menu, TextArea, Grid, Label, Popup, Container, Segment, Message, Button, Icon, Modal, Header, Form, Input, Checkbox, Table, Confirm, Dropdown } from 'semantic-ui-react'
import axios from 'axios'
import config from '../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import ChadderbotConnections from './ChadderbotConnections'
import GenerateUrl from './GenerateUrl'
import Resources from './Resources'
import Instructions from './Instructions'

toast.configure({
    autoClose: 5000,
    draggable: false,
});

export default function Connections(props) {

    const [connections, setConnections] = useState([])
    const [showAddConnectionModal, setShowAddConnectionModal] = useState(false)
    const [newApiKey, setNewApiKey] = useState('')
    const [newAppKey, setNewAppKey] = useState('')
    const [deletePageName, setDeletePageName] = useState('')
    const [deletePageId, setDeletePageId] = useState(null)
    const [showConfirmDelete, setShowConfirmDelete] = useState(false)
    const [isAddingConnection, setIsAddingConnection] = useState(false)
    // const [connectionsLimit, setConnectionsLimit] = props.connectionsLimit
    const [pageOptions, setPageOptions] = useState([])
    // const [urlVideoId, setUrlVideoId] = useState('')
    // const [urlPageId, setUrlPageId] = useState('[PAGE ID]')
    // const [urlStartedTag, setUrlStartedTag] = useState('[STARTED TAG]')
    // const [urlFinishedTag, setUrlFinishedTag] = useState('[FINISHED TAG]')
    // const [videoEndTag, setVideoEndTag] = useState('[END TAG]')
    // const [urlConnection, setUrlConnection] = useState({})
    // const [pageTags, setPageTags] = useState([])
    // const [isLoadingTags, setIsLoadingTags] = useState(false)
    // const [videoUrl, setVideoUrl] = useState('')
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [showAdminCodeModal, setShowAdminCodeModal] = useState(false)
    const [currentConnection, setCurrentConnection] = useState({})
    const [objectLoading, setObjectLoading] = useState('')
    const [activeTab, setActiveTab] = useState()
    // const [pageId, setPageId] = useState('116137703126070')
    const [pageId, setPageId] = useState(null)
    const [keyType, setKeyType] = useState('app')
    const [newConnectionName, setNewConnectionName] = useState('')
    
    const db = firebase.firestore()
    const userId = firebase.auth().currentUser.uid

    useEffect(() => {
        db.collection('chatbot_settings').where("uid", "==", userId).onSnapshot(snapshot => {
            // console.log(snapshot.docs)
            const newConnections = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }))
            setConnections(_.sortBy(newConnections, ['name']))

            const newPageOptions = snapshot.docs.map(doc => ({
                key: doc.id,
                text: doc.data().name,
                value: doc.id
            }))
            setPageOptions(newPageOptions)
        })
    }, [])

    // useEffect(() => {
    //     // setVideoUrl(`${window.location.origin}/video/${urlVideoId}?page_id=${urlPageId}&tags=${urlStartedTag},${urlFinishedTag},${videoEndTag}&user_id=[USER ID GOES HERE]`)
    //     setVideoUrl(`https://video.chatbot.media/video/${urlVideoId}?page_id=${urlPageId}&tags=${urlStartedTag},${urlFinishedTag},${videoEndTag}&user_id=[USER ID GOES HERE]`)
    // }, [urlVideoId, urlConnection, urlStartedTag, urlFinishedTag, videoEndTag])

    useEffect(() => {
        if(showAdminCodeModal === false)
            return
        // console.log("currnetConnection", currentConnection)
        if(currentConnection.access_token !== undefined)
            setActiveTab('welcome')
        else
            setActiveTab('generate')
    }, [showAdminCodeModal])

    const saveCurrentConnection = async () => {
        setObjectLoading('settings')
        const url = `${config.API_URL}/api/chadderbot/connection`
        await axios.put(url, currentConnection)
        setShowAdminCodeModal(false)
        setObjectLoading('')
    }

    const notify = (msg) => toast(msg)

    const addConnection = async () => {
        setIsAddingConnection(true)
        //get page info from ManyChat
        const url = `${config.API_URL}/api/manychat/addConnection`
        const response = await axios.post(url, {
            apiKey: newApiKey,
            appKey: newAppKey,
            connectionName: newConnectionName,
            uid: userId,
            subscriptionItemId: props.stripeCustomer.subscriptions.data[0].items.data[0].id
        })
        // console.log(response.data)

        setNewApiKey('')
        setNewAppKey('')
        setIsAddingConnection(false)
        setShowAddConnectionModal(false)
        if(response.data.result === "success"){
            toast.success(`Connection to ${response.data.name} Added`)
            props.onChange()
        } else {
            try {
                toast.error(`An Error Occured: ${response.data.error.message}`)
            } catch (err) {
                toast.error("An error occured.")
            }
        }
    }


    const syncStripeSubscription = async () => {
        return
        // console.log("syncSubscription")
        // const url = `${config.API_URL}/api/payments/syncSubscription`
        // const response = await axios.post(url, { 
        //     uid: userId, 
        //     subscriptionItemId: props.stripeCustomer.subscriptions.data[0].items.data[0].id
        // })
        // console.log(response)
    }

    const ApiKeyHelp = () => (
        <Popup
          trigger={<Icon name='info circle' color='grey' />}
          content='To find your ManyChat API key, go to ManyChat, click the "Settings" left menu nav item, then click the "API" at the top.'
        />
    )

    const connectionsLimit = props.connectionsLimit
    const first_name = "{{first_name}}"
    return (
        <React.Fragment>
            <Container style={{marginTop:60}}>
                {/* {connections.length < connectionsLimit
                ?
                    <Button icon labelPosition='left' positive floated='right' onClick={() => setShowAddConnectionModal(true)}>
                        <Icon name='add' />
                        Add Connection ({connectionsLimit-connections.length} remaining)
                    </Button>
                :
                    <span style={{float:'right'}}>
                        No connections available 
                        <Button positive style={{marginLeft:10}} onClick={() => setShowUpgradeModal(true)}>Upgrade</Button>
                    </span>
                } */}
                <Button icon labelPosition='left' positive floated='right' onClick={() => setShowAddConnectionModal(true)}>
                    <Icon name='add' />
                    Add ManyChat Connection
                </Button>
                <ChadderbotConnections />
                <h2>Connections</h2>
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Page Name</Table.HeaderCell>
                            <Table.HeaderCell>Connections</Table.HeaderCell>
                            <Table.HeaderCell>Admin Code{' '}
                                <Popup content='Click the link or type this code into your chatbot/page in Messenger to activate your account as an admin. You can then copy and paste youtube URLs to share.' trigger={<Icon name='question circle' color='grey' />} />
                            </Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                    {connections.map(connection => 
                        <Table.Row key={connection.id}>
                            <Table.Cell>
                                {connection.name} <a href={`https://m.me/${connection.id}`} target="_blank" style={{color:'#bab7b7'}}><Icon name="external" /></a>
                            </Table.Cell>
                            <Table.Cell>
                                {connection.manychat_app_key && <Label color='yellow' size='mini' style={{marginLeft:10}}>ManyChat App</Label>}
                                {connection.manychat_api_key && <Label color='green' size='mini' style={{marginLeft:10}}>ManyChat</Label>}
                                {connection.access_token && <Label color='blue' size='mini' style={{marginLeft:10}}>Facebook</Label>}
                            </Table.Cell>
                            <Table.Cell>
                                <a href={`https://m.me/${connection.id}?ref=ac--${connection.admin_code}`} target="_blank">{connection.admin_code}</a>
                            </Table.Cell>
                            <Table.Cell collapsing textAlign='right'>
                                
                                <Button icon 
                                    // disabled={connection.access_token === undefined}
                                    onClick={() => {
                                        setCurrentConnection(connection)
                                        setShowAdminCodeModal(true)
                                    }}>
                                    <Icon name="cog" />
                                </Button>
                                
                                <Button icon onClick={() => {
                                    setDeletePageName(connection.name)
                                    setDeletePageId(connection.id)
                                    setShowConfirmDelete(true)
                                }}>
                                    <Icon name="delete" />
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    )}
                    {connections.length === 0 &&
                        <Table.Row>
                            <Table.Cell>
                                No Connections
                            </Table.Cell>
                        </Table.Row>
                    }
                    </Table.Body>
                </Table>
                {/* <p>If you add a User Custom Field called <strong>ChatbotMedia: Last Video Watched</strong>, ChatbotMedial will populate it with the title and URL of the last video the subscriber interacts with, handy for admin notifications.</p> */}

                {/* <Popup trigger="Page" content="The page/chatbot you want to use this video URL in." /> */}
                
                {/* <GenerateUrl connections={connections} pageOptions={pageOptions} pageId={pageId} /> */}
                {/* {connections.map(connection => 
                    <div key={connection.id}>{connection.name}</div>
                )} */}
                <Modal onClose={() => setShowAddConnectionModal(false)} open={showAddConnectionModal} closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true} closeIcon={true}>
                    <Modal.Header>Add Connection</Modal.Header>
                    <Modal.Content>
                        <p>Your subscription will be automatically upgraded after adding the connection.</p>
                        <div style={{marginBottom:10}}>
                            <Radio label="APP Key" value="app" name="keyType" checked={keyType === 'app'} onChange={(e, data) => setKeyType(data.value)} />
                            <Radio label="API Key" value="api" name="keyType" checked={keyType === 'api'} onChange={(e, data) => setKeyType(data.value)} style={{marginLeft:20}} />
                        </div>
                        {keyType === 'app' &&
                            <Fragment>
                                <p><strong>Two ways to find your Manychat App Key in your Manychat account</strong></p>
                                <ol>
                                    <li>In your chatbot, click on  "Settings", then "Apps". Click on the "Chatbot.Media Youtube Video" app. A popup will allow you to copy your App Key.</li>
                                    <li>While editing a flow, add the "Youtube video progress" trigger. Inside the Information box, click the "Click here to access your App Key" link.</li>
                                </ol>
                                <Form>
                                    <Grid>
                                        <Grid.Row columns={2}>
                                            <Grid.Column>
                                                <Form.Field>
                                                    <label>Name/Title <small>(For your own use)</small></label>
                                                    <Input type="text"
                                                        value={newConnectionName}
                                                        onChange={e => setNewConnectionName(e.target.value)}
                                                        />
                                                </Form.Field>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Form.Field>
                                                    <label>ManyChat APP Key</label>
                                                    <Input type="text"
                                                        value={newAppKey}
                                                        onChange={e => setNewAppKey(e.target.value)}
                                                        />
                                                </Form.Field>
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form>
                            </Fragment>
                        }
                        {keyType === 'api' &&
                            <Input label="ManyChat API Key" type="text" fluid
                                value={newApiKey}
                                onChange={e => setNewApiKey(e.target.value)}
                            />
                        }
                        {/* <Message size='small' color='blue' icon='info circle' content='To find your ManyChat API key, go to ManyChat, click the "Settings" left menu nav item, then click the "API" at the top.' /> */}
                    </Modal.Content>
                    <Modal.Actions>
                        <ApiKeyHelp />
                        <a href="https://manychat.com" target="_blank">Go to Manychat</a>
                        <Button style={{marginLeft:20}} positive icon labelPosition="left" onClick={() => addConnection()} loading={isAddingConnection} disabled={isAddingConnection}>
                            <Icon name='add' />
                            Save Connection
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Modal onClose={() => setShowUpgradeModal(false)} open={showUpgradeModal} closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true} closeIcon={true}>
                    <Modal.Header>Upgrade Account</Modal.Header>
                    <Modal.Content>
                        <Header>Current Chatbots Limit: {connectionsLimit}</Header>
                        <Form.Select onChange={(e,data) => console.log(data)} 
                            options={[
                                {key:1, value:1, text:1},
                                {key:5, value:5, text:5},
                                {key:10, value:10, text:10},
                                {key:11, value:11, text:11},
                                {key:12, value:12, text:12},
                            ]}
                        />
                            
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowUpgradeModal(false)}>Cancel</Button>
                        <Button positive icon labelPosition="left" onClick={() => addConnection()} loading={isAddingConnection} disabled={isAddingConnection}>
                            <Icon name='add' />
                            Upgrade
                        </Button>
                    </Modal.Actions>
                </Modal>

                <Confirm
                    open={showConfirmDelete}
                    content={`Are you sure you want to delete the connection with ${deletePageName}?`}
                    onCancel={() => setShowConfirmDelete(false)}
                    onConfirm={() => {
                        db.collection('chatbot_settings').doc(`${deletePageId}`).delete()
                            .then(async () => {
                                const result = await syncStripeSubscription()
                                props.onChange()
                                toast.success("Connection Deleted")
                            })
                        setShowConfirmDelete(false)
                    }}
                />

                <Modal onClose={() => setShowAdminCodeModal(false)} open={showAdminCodeModal} closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true} closeIcon={true}>
                    <Modal.Header>Chatbot Settings</Modal.Header>
                    <Modal.Content scrolling>
                        
                        <Menu pointing secondary>
                            {currentConnection.access_token !== undefined &&
                                <Fragment>
                                    <Menu.Item
                                        name='Step 1 - Welcome Message'
                                        active={activeTab === 'welcome'}
                                        onClick={() => setActiveTab('welcome')}
                                    />
                                    {/* <Menu.Item
                                        name='Video Buttons'
                                        active={activeTab === 'buttons'}
                                        onClick={() => setActiveTab('buttons')}
                                    /> */}
                                    <Menu.Item
                                        name='Step 2 - Content'
                                        active={activeTab === 'step_2_content'}
                                        onClick={() => setActiveTab('step_2_content')}
                                    />
                                    <Menu.Item
                                        name='Instructions'
                                        active={activeTab === 'instructions'}
                                        onClick={() => setActiveTab('instructions')}
                                    />
                                    {/* <Menu.Menu>
                                        <Menu.Item
                                        name='Menu Items'
                                        active={activeTab === 'menu'}
                                        onClick={() => setActiveTab('menu')}
                                            />
                                        </Menu.Menu> */}
                                </Fragment>
                            }
                            {currentConnection.manychat_api_key !== undefined &&
                                <Fragment>
                                    {/* <Menu.Menu>
                                        <Menu.Item
                                        name='App Key'
                                        active={activeTab === 'appkey'}
                                        onClick={() => setActiveTab('appkey')}
                                        />
                                    </Menu.Menu> */}
                                    <Menu.Menu>
                                        <Menu.Item
                                        name='Generate URL'
                                        active={activeTab === 'generate'}
                                        onClick={() => setActiveTab('generate')}
                                        />
                                    </Menu.Menu>
                                    <Menu.Menu>
                                        <Menu.Item
                                        name='Resources'
                                        active={activeTab === 'resources'}
                                        onClick={() => setActiveTab('resources')}
                                        />
                                    </Menu.Menu>
                                </Fragment>
                            }
                        </Menu>
                        <Form>
                        {activeTab === 'welcome' &&
                            <Fragment>
                                {/* <h2>Welcome Message</h2> */}
                                <p>This message will be show the first time someone connects with your bot in reponse to a shared video. 
                                    Use {first_name} to automatically insert the user's first name in the message. (Limited to 600 characters)</p>
                                
                                <Form.Field>
                                    <label>Message Text</label>
                                    <TextArea placeholder='Welcome message...' onChange={(e) => {
                                        if(e.target.value.length <= 600)
                                            setCurrentConnection({...currentConnection, welcome_message:e.target.value})
                                    }} value={currentConnection.welcome_message} />
                                    <small>Characters: {currentConnection.welcome_message !== undefined ? currentConnection.welcome_message.length : 0}  (Limit: 600)</small>
                                </Form.Field>

                                
                                <Form.Field>
                                    <label>Image URL <small>Sent after the welcome message.</small></label>
                                    <Input value={currentConnection.image_url} 
                                        onChange={(e) => {
                                            setCurrentConnection({...currentConnection, image_url:e.target.value}) 
                                        }}
                                        type="text" fluid
                                        />
                                </Form.Field>

                                <Form.Field>
                                    <Checkbox label='Enable as default message'
                                        checked={currentConnection.enable_default_message}
                                        onChange={(e, data) => {
                                            setCurrentConnection({ ...currentConnection, enable_default_message: data.checked})
                                        }}
                                    />
                                </Form.Field>
                            </Fragment>
                        }

                        {activeTab === 'buttons' && 
                            <Fragment>
                            <h2>Video Buttons</h2>
                            <p>Each video can have up to two additional buttons.</p>
                            <Grid stackable>
                                <Grid.Row columns={2}>
                                    <Grid.Column>
                                        <Segment>
                                            <h3>Button 1</h3>
                                            <label>Button Text</label>
                                            <Input value={currentConnection.button1text} 
                                                onChange={(e) => {
                                                    if(e.target.value.length <= 20)
                                                        setCurrentConnection({...currentConnection, button1text:e.target.value}) 
                                                }}
                                                type="text" fluid
                                                />
                                            <label>URL/Link</label>
                                            <Input value={currentConnection.button1url} 
                                                onChange={(e) => setCurrentConnection({...currentConnection, button1url:e.target.value})} 
                                                type="text" fluid 
                                                />
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Segment>
                                            <h3>Button 2</h3>
                                            <label>Button Text</label>
                                            <Input value={currentConnection.button2text} 
                                                onChange={(e) => {
                                                    if(e.target.value.length <= 20)
                                                        setCurrentConnection({...currentConnection, button2text:e.target.value})
                                                }} type="text" fluid error={false} 
                                                />
                                            <label>URL/Link</label>
                                            <Input value={currentConnection.button2url} onChange={(e) => setCurrentConnection({...currentConnection, button2url:e.target.value})} type="url" fluid error={false} />
                                        </Segment>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            </Fragment>
                        }

                        {activeTab === 'step_2_content' &&
                            <Fragment>
                                {/* <h2>Step 2 Content</h2> */}
                                {/* <p>If you have a <a href="https://www.marketingboost.com/#a_aid=5bc217c663149" target="_blank">Marketing Boost account</a>, you can use it to automatically deliver a reward to people that watch a video 
                                    that you invite them to watch. Log into your Marketing Boost account and create a Chat Bot URL. Copy and paste it in the field below.</p> */}
                                <p>After the initial welcome message and optional video content is delivered, the below content will be sent.</p>
                                <Form.Field>
                                    <label>File URL <small>(Optional. You can deliver a file, such as a pdf into the chatbot.)</small></label>
                                    <Input value={currentConnection.file_url} 
                                        onChange={(e) => {
                                            setCurrentConnection({...currentConnection, file_url:e.target.value}) 
                                        }}
                                        type="text" fluid
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Message Text</label> 
                                    <TextArea placeholder='Enter text to send when video is completed...' onChange={(e) => {
                                        if(e.target.value.length <= 600)
                                            setCurrentConnection({...currentConnection, after_video_message:e.target.value})
                                    }} value={currentConnection.after_video_message} />
                                    <small>Characters: {currentConnection.after_video_message !== undefined ? currentConnection.after_video_message.length : 0} (Limit: 600)</small>
                                </Form.Field>
                                
                                <Form.Field>
                                    <label>Buttons (optional)</label>
                                    <Grid stackable>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={4}>
                                                <Input placeholder="Button 1 Text" fluid 
                                                    value={currentConnection.button1text} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button1text:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                            <Input placeholder="Button 1 URL" fluid 
                                                    value={currentConnection.button1url} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button1url:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={4}>
                                                <Input placeholder="Button 2 Text" fluid 
                                                    value={currentConnection.button2text} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button2text:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                            <Input placeholder="Button 2 URL" fluid 
                                                    value={currentConnection.button2url} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button2url:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row columns={2}>
                                            <Grid.Column width={4}>
                                                <Input placeholder="Button 3 Text" fluid 
                                                    value={currentConnection.button3text} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button3text:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                            <Grid.Column width={12}>
                                            <Input placeholder="Button 3 URL" fluid 
                                                    value={currentConnection.button3url} 
                                                    onChange={(e) => {
                                                        if(e.target.value.length <= 20)
                                                            setCurrentConnection({...currentConnection, button3url:e.target.value}) 
                                                        }
                                                    } 
                                                />
                                            </Grid.Column>
                                        </Grid.Row>
                                    </Grid>
                                </Form.Field>

                                <Form.Field>
                                    <Checkbox label='Send immediately without requiring video to be watched.' 
                                        checked={currentConnection.send_immediately}
                                        onChange={(e, data) => {
                                            setCurrentConnection({ ...currentConnection, send_immediately: data.checked})
                                        }}
                                    />
                                </Form.Field>
                                
                            </Fragment>
                        }

                        {activeTab === 'instructions' &&
                            <Instructions />
                        }

                        {activeTab === 'menu' &&
                            <Fragment>
                                <h2>Admin Connect Code</h2>
                                <p>To enable your Messenger account as an admin on your chatbot, click the number below, or type it into your chatbot Messenger thread.</p>
                                <h1><a href={`https://m.me/${currentConnection.id}?ref=ac--${currentConnection.admin_code}`} target="_blank">{currentConnection.admin_code}</a></h1>
                            </Fragment>
                        }
                        {/* {currentConnection.manychat_app_key !== undefined &&
                            <Fragment>
                                {activeTab === 'appkey' && 
                                    <Form>
                                        <Form.Group widths='equal'>
                                            <Form.Field>
                                                <label>App Key</label>
                                                <Input value={currentConnection.name} type="text" fluid />
                                            </Form.Field>
                                        </Form.Group>
                                    </Form>
                                }
                            </Fragment>
                        } */}
                        {currentConnection.manychat_api_key !== undefined && 
                            <Fragment>
                            {activeTab === 'generate' && 
                                <GenerateUrl connections={connections} pageOptions={pageOptions} pageId={currentConnection.id} />
                            }
                            {activeTab === 'resources' &&
                                <Resources />
                            }
                            </Fragment>
                        }
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <a href={`https://m.me/${currentConnection.id}`} target="_blank">Go to Chatbot</a>
                        <Button style={{marginLeft:20}} icon labelPosition="left" onClick={() => setShowAdminCodeModal(false)}>
                            <Icon name='close' />
                            Close
                        </Button>
                        <Button loading={objectLoading === 'settings'} style={{marginLeft:10}} positive icon labelPosition="left" onClick={() => saveCurrentConnection()}>
                            <Icon name='checkmark' />
                            Save
                        </Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        </React.Fragment>
    )

}