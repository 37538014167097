import React, { useState, useEffect } from "react"
import firebase from 'firebase'
import { Container } from 'semantic-ui-react'
import YouTube from 'react-youtube'
import _ from 'lodash'
import axios from 'axios'
import config from '../config'
import useInterval from '@use-it/interval';
import '../styles/videos.css'


export default function Videos(props) {
    const [video, setVideo] = useState(null)
    const [currentVideo, setCurrentVideo] = useState(null)
    const [lastVideo, setLastVideo] = useState(null)
    const [apiEndPoint, setApiEndPoint] = useState('manychat')

    const qs = (key) => {
        key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
        var match = window.location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
    }

    useEffect(() => {
        let tags = ["Video Started", "Video Finished", "Video End"]

        if(qs('tags') !== null)
            tags = qs('tags').split(',')

        if(qs('api') === 'cb')
            setApiEndPoint('chadderbot')

        const obj = {
            videoYoutubeId: props.match.params.videoId,
            startedTag: tags[0],
            finishedTag: tags[1],
            endTag: tags[2],
            userId: qs("user_id"),
            pageId: qs("page_id")
        }

        firebase.firestore().collection('chatbot_settings').doc(qs("page_id")).onSnapshot(settings => {
            if(settings.exists){
                // setUserData(user.data())
                // setIsLoading(false)
                // getStripeCustomer()
                setCurrentVideo(obj)

                // record pageview
                axios.post(`${config.API_URL}/api/payments/pageview`,
                {
                    uid: settings.data().uid
                })
            } else {
                //page not connected
                setCurrentVideo({videoYoutubeId: "ysDZWPXCy3A"})
            }
        })
    }, [])

    useEffect(() => {
        // console.log("setLastVideo", currentVideo)
        setLastVideo(currentVideo)
    }, [currentVideo])

    let watched = []
    let reported = {}
    const setWatched = value => {
        watched = value
    }
    const setReported = value => {
        reported = value
    }

    useInterval(() => {
        calculatedPercentWatched()
    }, 200)
    
    const _onReady = (event) => {
        setVideo(event.target)
    }

    const _onPlay = (event) => {
        setVideo(event.target)
    }

    const _onPause = (event) => {}
    const _onEnd = (event) => {
        reportStatus('endTag')
        reportActivity('end')

        //this line will cause the thumbnail to show, rather than related videos
        video.stopVideo()
    }

    const calculatedPercentWatched = () => {
        if(currentVideo === null || video === null || video.getPlayerState() !== 1)
            return
        
        const percentWatched = Math.round(video.getCurrentTime()/video.getDuration()*100)
        // console.log(percentWatched)
        if(percentWatched > 0){
            watched.push(percentWatched)
            watched = _.uniq(watched)
        }

        //report started watching / 1%
        if(watched.length === 1 && reported[1] === undefined){
            const newReported = reported
            newReported[1] = true
            setReported(newReported)
            reportStatus('startedTag')
            reportActivity('started')
            triggerProgress(1)
        }
        
        //report progress if multiple of 10
        if(watched.length % 10 === 0 && reported[watched.length] === undefined){
            const newReported = reported
            newReported[watched.length] = true
            setReported(newReported)
            triggerProgress(watched.length)
        }

        //report finished watching
        if(watched.length === 95 && reported[95] === undefined){
            const newReported = reported
            newReported[95] = true
            setReported(newReported)
            reportStatus('finishedTag')
            reportActivity('finished')
            // triggerProgress(95)
        }
    }
    
    const reportStatus = (tag) => {
        if(tag === undefined || tag === '' || _.isEmpty(tag) || tag === '[END TAG]' || qs('trigger') === '1')
            return
        
        axios.post(`${config.API_URL}/api/${apiEndPoint}/setCustomFieldByName`,
            {
                pageId: currentVideo.pageId,
                subscriberId: currentVideo.userId,
                field: 'ChatbotMedia: Last Video Watched',
                value: `${video.getVideoData().title} - ${video.getVideoUrl()}`
            })

        axios.post(
            `${config.API_URL}/api/${apiEndPoint}/addTag`,
            {
                pageId: currentVideo.pageId,
                subscriberId: currentVideo.userId,
                tag: currentVideo[tag],
                videoId: currentVideo.videoYoutubeId,
                videoTitle: video.getVideoData().title
            })

        return(true)
    }

    const reportActivity = (activity) => {
        if(qs('trigger') === '1')
            return

        const time = Date.now()
        axios.post(
            `${config.API_URL}/api/manychat/activity`,
            {
                pageId: currentVideo.pageId,
                subscriberId: currentVideo.userId,
                time,
                activity,
                videoTitle: video.getVideoData().title, 
                videoYoutubeId: currentVideo.videoYoutubeId
                // videoYoutubeId: props.match.params.videoId
            }
        )
    }

    const triggerProgress = (progress) => {
        if(qs('trigger') !== '1')
            return

        axios.post(
            `${config.API_URL}/api/manychat/trigger_progress`,
            {
                pageId: currentVideo.pageId,
                subscriberId: currentVideo.userId,
                progress,
                video_title: video.getVideoData().title, 
                video_id: currentVideo.videoYoutubeId
            }
        )
    }
    
    const opts = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            rel: 0
        }
    }

    if(currentVideo === null){
        return <div>Loading...</div>
    }

    return (
        <div style={{backgroundColor:'#000', height:'100vh'}}>
            <YouTube
                videoId={currentVideo.videoYoutubeId}
                opts={opts}
                onReady={_onReady}
                containerClassName="resp-container"
                className="resp-iframe"
                onPlay={_onPlay}
                onPause={_onPause}
                onEnd={_onEnd}
                onError={event => console.error("error")}
                // onStateChange={event => console.log("onStateChange ::", event)}
                // onPlaybackRateChange={event => console.log("onPlaybackRateChange ::", event)}
                // onPlaybackQualityChange={event => console.log("onPlaybackQualityChange ::", event)}
            />
            {/* <Container textAlign="center">
                <a style={{marginTop:'4%'}} className="ui button margin-top" href={window.location.href} target="_blank">Open in New Page</a>
            </Container> */}
        </div>
    )


}