import React, { useState } from "react"
import { Container, Segment } from 'semantic-ui-react'

export default function ContestRules() {

    const campaignUrl = <strong>Special Invitation Chatbot</strong>
    const age = 18
    const location = 'the United States'
    const company = 'Chatbot Media LLC'
    const prize = 'a vacation voucher redeemable for a multi-night stay at participating resorts in the United States'
    const notification = 'Facebook Messenger™️'
    const country = 'the United States'
    const state = 'Florida'
    const privacy_link = <a href="/privacy">click here</a>
    const selection_method = 'a random selection'
    const time_limit = '30 days'
    const city = 'Madeira Beach'
    const address = '15416 2nd St E'
    const zip = '33708'

    return (
        <Container>
            <Segment padded='very' basic style={{marginTop:40, marginBottom:40}}>
                <div class="sixteen wide column">
                    <h2>Contest Rules</h2>
                    
                    <p>NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE DOES NOT INCREASE THE CHANCES OF WINNING.</p>

                    <ol>
                        <li>
                            Eligibility: This Campaign is open only to those who sign up at the {campaignUrl} and who are {age} years of age or older as of the date of entry. The Campaign is only open to legal residents of {location} and is void where prohibited by law. Employees of {company}, its affiliates, subsidiaries, advertising and promotion agencies, and suppliers, (collectively the “Employees”), and immediate family members and/or those living in the same household of Employees are not eligible to participate in the Campaign. The Campaign is subject to all applicable federal, state, and local laws and regulations. Void where prohibited.
                        </li>
                        <li>
                            Agreement to Rules: By participating, the Contestant (“You”) agree to be fully unconditionally bound by these Rules, and You represent and warrant that You meet the eligibility requirements. In addition, You agree to accept the decisions of {company} as final and binding as it relates to the content of this Campaign.
                        </li>
                        {/* <li>
                            Campaign Period: Entries will be accepted online starting on [start date, time, and time zone] and ending [end date, time, and time zone]. All online entries must be received by [end date, time, and time zone].
                        </li> */}
                        <li>
                            How to Enter: The Campaign must be entered by submitting an entry using the online form provided at {campaignUrl}. The entry must fulfill all Campaign requirements, as specified, to be eligible to win a prize. Entries that are incomplete or do not adhere to the rules or specifications may be disqualified at the sole discretion of {company}. Optional verbiage to include: You may enter only once. You must provide the information requested. You may not enter more times than indicated by using multiple email addresses, identities, or devices in an attempt to circumvent the rules. If You use fraudulent methods or otherwise attempt to circumvent the rules, your submission may be removed from eligibility at the sole discretion of {company}.
                        </li>
                        <li>
                            Prizes: The Winner(s) of the Campaign (the “Winner”) will receive {prize}. Actual/appraised value may differ at time of prize award. The specifics of the prize shall be solely determined by {company}. No cash or other prize substitution shall be permitted except at {company}'s discretion. The prize is nontransferable. Any and all prize-related expenses, including without limitation any and all federal, state, and/or local taxes, shall be the sole responsibility of Winner. No substitution of prize or transfer/assignment of prize to others or request for the cash equivalent by Winner is permitted. Acceptance of prize constitutes permission for {company} to use Winner’s name, likeness, and entry for purposes of advertising and trade without further compensation unless prohibited by law.
                        </li>
                        <li>
                            Odds: The odds of winning depend on the number of eligible entries received.
                        </li>
                        <li>
                            Winner Selection and Notification: Winner will be selected by {selection_method} under the supervision of {company}. Winner will be notified by {notification} within five (5) days following selection of Winner. {company} shall have no liability for Winner’s failure to receive notices due to spam, junk e-mail, or other security settings or for Winner’s provision of incorrect or otherwise non-functioning contact information. If Winner cannot be contacted, is ineligible, fails to claim the prize within {time_limit} from the time award notification was sent, or fails to timely return a completed and executed declaration and release as required, the prize may be forfeited and an alternate Winner selected. Receipt by Winner of the prize offered in this Campaign is conditioned upon compliance with any and all federal, state, and local laws and regulations. ANY VIOLATION OF THESE OFFICIAL RULES BY WINNER (AT {company}‘S SOLE DISCRETION) WILL RESULT IN WINNER’S DISQUALIFICATION AS WINNER OF THE CAMPAIGN, AND ALL PRIVILEGES AS WINNER WILL BE IMMEDIATELY TERMINATED.
                        </li>
                        <li>
                            Rights Granted by You: By entering this content (e.g., photo, video, text, etc.), You understand and agree that {company}, anyone acting on behalf of {company}, and {company}’s licensees, successors, and assigns, shall have the right, where permitted by law, to print, publish, broadcast, distribute, and use in any media now known or hereafter developed, in perpetuity and throughout the World, without limitation, your entry, name, portrait, picture, voice, likeness, image, statements about the Campaign, and biographical information for news, publicity, information, trade, advertising, public relations, and promotional purposes. without any further compensation, notice, review, or consent. Optional verbiage for Contests: By entering this content, You represent and warrant that your entry is an original work of authorship, and does not violate any third party’s proprietary or intellectual property rights. If your entry infringes upon the intellectual property right of another, You will be disqualified at the sole discretion of {company}. If the content of your entry is claimed to constitute infringement of any proprietary or intellectual proprietary rights of any third party, You shall, at your sole expense, defend or settle against such claims. You shall indemnify, defend, and hold harmless {company} from and against any suit, proceeding, claims, liability, loss, damage, costs or expense, which {company} may incur, suffer, or be required to pay arising out of such infringement or suspected infringement of any third party’s right.
                        </li>
                        <li>
                            Terms & Conditions: {company} reserves the right, in its sole discretion, to cancel, terminate, modify or suspend the Campaign should virus, bug, non-authorized human intervention, fraud, or other cause beyond {company}’s control corrupt or affect the administration, security, fairness, or proper conduct of the Campaign. In such case, {company} may select the Winner from all eligible entries received prior to and/or after (if appropriate) the action taken by {company}. {company} reserves the right, in its sole discretion, to disqualify any individual who tampers or attempts to tamper with the entry process or the operation of the Campaign or website or violates these Terms & Conditions. {company} has the right, in its sole discretion, to maintain the integrity of the Campaign, to void votes for any reason, including, but not limited to: multiple entries from the same user from different IP addresses; multiple entries from the same computer in excess of that allowed by Campaign rules; or the use of bots, macros, scripts, or other technical means for entering. Any attempt by an entrant to deliberately damage any website or undermine the legitimate operation of the Campaign may be a violation of criminal and civil laws. Should such an attempt be made, {company} reserves the right to seek damages to the fullest extent permitted by law.
                        </li>
                        <li>
                            Limitation of Liability: By entering, You agree to release and hold harmless {company} and its subsidiaries, affiliates, advertising and promotion agencies, partners, representatives, agents, successors, assigns, employees, officers, and directors from any liability, illness, injury, death, loss, litigation, claim, or damage that may occur, directly or indirectly, whether caused by negligence or not, from: (i) such entrant’s participation in the Campaign and/or his/her acceptance, possession, use, or misuse of any prize or any portion thereof; (ii) technical failures of any kind, including but not limited to the malfunction of any computer, cable, network, hardware, or software, or other mechanical equipment; (iii) the unavailability or inaccessibility of any transmissions, telephone, or Internet service; (iv) unauthorized human intervention in any part of the entry process or the Promotion; (v) electronic or human error in the administration of the Promotion or the processing of entries.
                        </li>
                        <li>
                            Disputes: THIS Campaign IS GOVERNED BY THE LAWS OF {country} AND {state}, WITHOUT RESPECT TO CONFLICT OF LAW DOCTRINES. As a condition of participating in this Campaign, participant agrees that any and all disputes that cannot be resolved between the parties, and causes of action arising out of or connected with this Campaign, shall be resolved individually, without resort to any form of class action, exclusively before a court located in {state} having jurisdiction. Further, in any such dispute, under no circumstances shall participant be permitted to obtain awards for, and hereby waives all rights to, punitive, incidental, or consequential damages, including reasonable attorney’s fees, other than participant’s actual out-of-pocket expenses (i.e. costs associated with entering this Campaign). Participant further waives all rights to have damages multiplied or increased.
                        </li>
                        <li>
                            Privacy Policy: Information submitted with an entry is subject to the Privacy Policy stated on the {company} website. To read the Privacy Policy, {privacy_link}. Note: a privacy policy is optional, but may be required when running a promotion on the web when using third party platforms or when using features from social channels. Including this information makes it clear to your users how you are going to use their information.
                        </li>
                        <li>
                            Winners List: To obtain a copy of the Winner’s name or a copy of these Official Rules, mail your request along with a stamped, self-addressed envelope to: {company} {address} {city} {state} {zip} {country}.
                        </li>
                        <li>
                            Sponsor: The Sponsor of the Campaign is {company} {address} {city} {state} {zip} {country}.
                        </li>
                        <li>
                            Facebook: The Campaign hosted by {company} is in no way sponsored, endorsed, administered by, or associated with Facebook.
                        </li>
                    </ol>
                    <h2>Vaction Voucher Terms</h2>
                    <ol>
                        <li>There are NO timeshare presentations required to use this vacation incentive or any other hoops you will need to jump to redeem. Simply activate online by prepaying the hotel taxes and fees
                        associated with the destination. After activation, login online and search for your preferred participating resort/hotel and preferred travel dates.</li>
                        <li>The expiration date on this incentive pertains only to the redemption or activation of this incentive and not actual travel. You must activate this incentive within 30 days of receiving it. Travel can
                        be completed within 24 months after activation. This incentive cannot be combined with any other hotel stay or promotional vacation offer. This incentive cannot be used in conjunction with
                        another similar or same promotional offer.</li>
                        <li>GROUP travel is NOT allowed only one incentive per household and resort/hotel is allowed. Reedeemvacations.com will only honor one incentive per family, friends, acquaintances or known
                        groups traveling on the same, similar and/or adjoining arrival and departure dates. Unfortunately, only 1 party among friends, family, acquaintances or known groups can use the complimentary
                        vacation incentive for the same or overlapping travel dates. Otherwise, there are additional costs associated with group travel.</li>
                        <li>This incentive will be considered void if bartered or purchased by the recipient. The incentive is valid for a reservation request offering Resort/Hotel Accommodations in your choice of
                        destinations provided by redeemvacations.com These rooms are for maximum of 2 adults and up to 2 children up to the age of 12. based on room type and availability and at least one occupant
                        must be 21 years of age or older. Your cost to activate and redeem this incentive and book the reservation includes our recovery fees, local government taxes, and tourism fees which vary by
                        destination. The main reservation holder must also have a Major credit or a bank debit card with a Visa or Mastercard or American Express logo, plus a valid government-issued ID to check-in.</li>
                        <li>The recipient of this incentive will receive luxury hotel accommodation room rate free of charge, which retail is valued at up to $350 US per night, however recipient is responsible for all other
                        expenses including but not limited to: airfare, transfers, food & beverages, gratuities, resort fees if applicable and any other personal or miscellaneous expenses. (Resort fees are now
                        commonplace in hotels around the world however not always applicable. For example in Orlando FL they run from $5.00 to $13.00 per night, In Las Vegas resort fees run from $16.00 to $38 per
                        night Paid directly to the resort. If applicable the resort fees will be disclosed when choosing a hotel and preferred travel dates. Resort fees cover items like WiFi Internet Access, Local Calls, &
                        Resort amenities).</li>
                        <li>All reservations require a minimum of 30 days advance notice to book. Reservations are based on availability and will not be available during major holidays or resort special events. Check-in is
                        available 7 days a week. Weekends may have a small additional surcharge.</li>
                        <li>This incentive activation is FINAL & NON-REFUNDABLE and NON-TRANSFERABLE, the end user will have 24 months to choose from available travel dates and participating resort/hotel options.
                        Once travel dates are confirmed no changes will be allowed. Cancellations after dates are confirmed will result in forfeiture of the activation fees. (The inventory used for these rooms are not as
                        flexible as booking at regular retail rack rates, once confirmed changes will not be allowed and cancellations or no-shows will result in forfeiture of fees paid)</li>
                        <li>The user must reside at least 100 miles or 160 kilometers away from the destination resort of choice or choose a different resort offer from the available destination options.</li>
                        <li>This incentive has no cash surrender value, void if discontinued and where prohibited by law. The terms of this offer are subject to change without notice. redeemvacations.com reserves the right
                        to offer other hotels with equal or similar value with incentive holder approval if required due to availability. Do not rely upon representations other than what is printed on this incentive.</li>
                        <li>If you have been awarded more than one vacation incentive you are only allowed to redeem 1 incentive every 12 months per household, The travel incentive destination can only be redeemed
                        once per household lifetime.</li>
                        <li>To redeem simply activate the incentive online and then search online at redeemvacations.com for the destination of choice and participating resort/hotels and travel dates.</li>
                    </ol>
                </div>
            </Segment>
        </Container>
    )
}