import React from 'react';
import { Segment, Container, Dimmer, Loader } from 'semantic-ui-react'

export default function Loading(props) {
    return (
        <Segment style={{height:100}}>
            <Dimmer active inverted>
                <Loader inverted>Loading</Loader>
            </Dimmer>
        </Segment>
    )
    
}