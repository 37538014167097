const prod = {
  FACEBOOK_APP_ID: "1805157243069318",
  API_URL: `https://${window.location.host}`,
  STRIPE_KEY: "pk_live_TTGHmW937a7RZCB9eI0h8Lqw",
  STRIPE_PLAN_ID: 'plan_cm_monthly_tiered',
  // STRIPE_PRODUCT_ID: 'prod_G4qsCL5j1rsVPx'
  STRIPE_PRODUCT_ID: 'prod_G6a0ZYj2e9jmdl'
}

const dev = {
	// FACEBOOK_APP_ID: "2012606288998110",
	FACEBOOK_APP_ID: "1805157243069318",
  API_URL: "http://localhost:5000/chatbotmedia1/us-central1/app",
  STRIPE_KEY: "pk_test_96cByfdt6bKIGnBs3xTLe8tQ",
  STRIPE_PLAN_ID: 'plan_cm_monthly_tiered',
  // STRIPE_PRODUCT_ID: 'prod_FtT6lP5407hCE9'
  STRIPE_PRODUCT_ID: 'prod_G6FwIv7vdSoTZQ'
}
  
// Default to dev if not set
const config = window.location.hostname === 'localhost'
  ? dev
  : prod;

export default {
	// Add common config values here
  ...config
}