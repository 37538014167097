import React, { Fragment } from "react"
import { Grid, Header } from 'semantic-ui-react'


export default function Instructions(){

    return (
        <div>
            <h2>How to use your chatbot</h2>
            <Grid stackable>
                <Grid.Row>
                    <Grid.Column>
                        <hr />
                        <Header>Managing Videos</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width='4'>
                        <strong>Adding Videos:</strong>
                    </Grid.Column>
                    <Grid.Column width='12'>
                        Simply copy and paste any youtube video URL (public or unlisted) into your chatbot. It will automatically be recognized as a video and will generate sharing links.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width='4'>
                        <strong>Deleting Videos:</strong>
                    </Grid.Column>
                    <Grid.Column width='12'>
                        Type "videos" into your chatbot. A carousel of all your saved videos will be shown. Each video will have a "Delete" button. Tap it to remove it from your saved videos.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width='4'>
                        <strong>Default Video:</strong>
                    </Grid.Column>
                    <Grid.Column width='12'>
                        When someone randomly finds your page and sends you a message, you might want to show a certain video by default. Type "videos" into your chatbot. A carousel of all your saved videos will be shown. Each video will have a "Make Default" button. Tap it to make it the default video.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <hr />
                        <Header>
                            Sharing Videos
                            {/* <Header.Subheader>Type the word "videos" to generate a list of your saved videos.</Header.Subheader> */}
                        </Header>
                        <p>Type the word "videos" into your chatbot to see what videos you've saved. Tap the "Share" button for the video you want to send. Once the share card and link appear, tap the share icon next to the video card to share with friends in Messenger. You can also copy and paste the special URL that is generated and send it via email, text, social media post, etc.</p>
                    </Grid.Column>
                </Grid.Row>
                {/* <Grid.Row>
                    <Grid.Column width='4'>
                        <strong>On Mobile</strong>
                    </Grid.Column>
                    <Grid.Column width='12'>
                        Type the word "videos" into your chatbot to see what videos you've saved. When sharing from mobile, you tap the share icon next to the video card to share with friends in Messenger. You can also copy and paste the special URL that is generated and send it via email, text, social media post, etc.
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width='4'>
                        <strong>On Desktop</strong>
                    </Grid.Column>
                    <Grid.Column width='12'>
                        Desktop sharing is limited to sharing the URL link only. You can share the special URL in any way you want (email, text, social media, etc).
                    </Grid.Column>
                </Grid.Row> */}
            </Grid>
        </div>
    )
}