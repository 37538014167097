import React, { useState, useEffect, Fragment } from "react"
import firebase from 'firebase'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { Dimmer, Loader, Image, Popup, Container, Segment, Message, Button, Icon, Modal, Header, Form, Input, Checkbox, Table, Confirm, Dropdown } from 'semantic-ui-react'
import FacebookLogin from 'react-facebook-login'
import config from '../config'
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

toast.configure({
    autoClose: 5000,
    draggable: false,
});

export default function ChadderbotConnections(props) {
    // const [userName, setUserName] = useState(firebase.auth().currentUser.displayName)
    const [userEmail, setUserEmail] = useState(firebase.auth().currentUser.email)
    const [connections, setConnections] = useState([])
    // const [showAddConnectionModal, setShowAddConnectionModal] = useState(false)
    const [facebookPages, setFacebookPages] = useState([])
    const [showConnectPageModal, setShowConnectPageModal] = useState(false)
    const [loadingFacebookPages, setLoadingFacebookPages] = useState(false)
    const [connectingPageId, setConnectingPageId] = useState(null)
    // const user = firebase.auth().currentUser
    // const db = firebase.firestore()
    // const userId = user.uid

    // if(userEmail !== "chad@chadwyatt.com")
    //     return null

    const responseFacebook = async response => {
        setShowConnectPageModal(true)
        setLoadingFacebookPages(true)
        // console.log(response)
        const url = `${config.API_URL}/api/chadderbot/pages`
        const pages = await axios.post(url, {
            fb_user_id: response.id,
            short_lived_token: response.accessToken
        })
        // console.log("pages response", pages.data)
        setFacebookPages(_.sortBy(pages.data.data, ['name']))
        setLoadingFacebookPages(false)
    }


    const connectPage = async page => {
        setConnectingPageId(page.id)
        setLoadingFacebookPages(true)
        const data = {
            uid: firebase.auth().currentUser.uid,
            name: page.name,
            id: page.id,
            access_token: page.access_token
        }
        const url = `${config.API_URL}/api/chadderbot/connection`
        const connection = await axios.post(url, data)
        // console.log(connection)
        setConnectingPageId(null)
        setLoadingFacebookPages(false)
        setShowConnectPageModal(false)
        toast.success("Page connected")
    }

    const ConnectPageModal = () => {
        

        return(
            <Modal loading={true} onClose={() => setShowConnectPageModal(false)} open={showConnectPageModal} closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true} closeIcon={true}>
                <Modal.Header>Connect Facebook Page</Modal.Header>
                <Modal.Content scrolling>
                    <Dimmer active={loadingFacebookPages} inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>
                    <Table celled striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Page Name</Table.HeaderCell>
                                <Table.HeaderCell>Connect</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {facebookPages.map(page => 
                            <Table.Row key={page.id}>
                                <Table.Cell>
                                    {/* <img alt="" className="ui centered circular image padded" style={{marginBottom:'10px', minHeight:'100px'}} src={`https://graph.facebook.com/v2.6/${page.id}/picture?type=square`} /> */}
                                    <Image size='mini' floated='left' circular src={`https://graph.facebook.com/v2.6/${page.id}/picture?type=square`} />
                                    {page.name}
                                </Table.Cell>
                                <Table.Cell collapsing textAlign='right'>
                                    <Button icon labelPosition="left" positive 
                                    loading={connectingPageId === page.id}
                                    disabled={connectingPageId === page.id}
                                    onClick={() => {
                                        connectPage(page)
                                        // setDeletePageName(connection.name)
                                        // setDeletePageId(connection.id)
                                        // setShowConfirmDelete(true)
                                    }}>
                                        <Icon name="plus" />
                                        Connect
                                    </Button>
                                </Table.Cell>
                            </Table.Row>
                        )}
                        {facebookPages.length === 0 &&
                            <Table.Row>
                                <Table.Cell>
                                    No Pages
                                </Table.Cell>
                            </Table.Row>
                        }
                        </Table.Body>
                    </Table>
                </Modal.Content>
                <Modal.Actions>
                    <a href="https://www.facebook.com/pages/creation/" target="_blank"><Button>Create Facebook Page</Button></a>
                    <Button style={{marginLeft:20}} icon labelPosition="left" onClick={() => setShowConnectPageModal(false)}>
                        <Icon name='close' />
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        )
    }

    return (
        <Fragment>
            <FacebookLogin
                // appId={config.FACEBOOK_APP_ID}
                appId="1805157243069318"
                autoLoad={false}
                fields="name,email,picture"
                scope="email,public_profile,manage_pages,pages_messaging"
                callback={response => responseFacebook(response)}
                // icon="fab fa-facebook"
                textButton={<Fragment><Icon name="facebook"/>&nbsp;Connect Facebook Page</Fragment>}
                size="small"
                style={{float:"right"}}
                // cssClass={this.props.cssClass}
            />
            <ConnectPageModal />
        </Fragment>
    )
}