import React, { useState, useEffect, Fragment } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Grid, Button, Header } from 'semantic-ui-react'


export default function LoginInline(props) {
    const [isSignedIn, setIsSignedIn] = useState(false)

    useEffect(() => {
        if(firebase.auth().currentUser)
            setIsSignedIn(true)
        else
            setIsSignedIn(false)
    })  

    const signInOptions = [
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID
    ]
    if(props.match && props.match.params.email === 'email')
        signInOptions.push(firebase.auth.EmailAuthProvider.PROVIDER_ID)

    const uiConfig = {
        signInFlow: 'popup',
        signInOptions,
        callbacks: {
            signInSuccessWithAuthResult: result => {
                setIsSignedIn(true)
                if(props && props.history)
                    props.history.push("/account")
                else if(props.callback !== undefined)
                    props.callback()
            }
        }
    }

    const logOut = () => {
        console.log("Handle LogOut")
        firebase.auth().signOut()
        setIsSignedIn(false)
    }
    

    if (!isSignedIn || props.reauth) {
        return <StyledFirebaseAuth customParameters={{auth_type: 'reauthenticate'}} uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>
    }
    
    return (
        <div>
            <Header>
                Welcome {firebase.auth().currentUser.displayName}!
                <Header.Subheader>You are signed in!</Header.Subheader>
            </Header>
            <Button onClick={() => logOut()} size='large'>Sign-out</Button>
        </div>
    )
    
}