import React, { useState, useEffect, Fragment } from "react"
import firebase from 'firebase'
import _ from 'lodash'
import Payment from './Payment'
import { StripeProvider, Elements, injectStripe, CardElement } from 'react-stripe-elements'
import config from '../config'
import { Menu, Message, Icon, Grid, Button, Segment, Table, Modal, Image, Select, Dimmer, Loader } from 'semantic-ui-react'
import axios from 'axios'
import moment from 'moment'
// import { Subscriber } from "rxjs";
import Connections from './Connections'
import Templates from './Templates'
import Training from './Training'
// import Resources from './Resources'
// import ChadderbotConnections from './ChadderbotConnections'

export default function Dashboard(props) {
    const [isLoading, setIsLoading] = useState(true)
    const [userName, setUserName] = useState(firebase.auth().currentUser.displayName)
    const [userEmail, setUserEmail] = useState(firebase.auth().currentUser.email)
    const [userPhone, setUserPhone] = useState(firebase.auth().currentUser.phoneNumber)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [userData, setUserData] = useState({})
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [showReinstateModal, setShowReinstateModal] = useState(false)
    const [stripeCustomer, setStripeCustomer] = useState(null)
    const [isLoadingStripeCustomer, setIsLoadingStripeCustomer] = useState(false)
    const [currentSubscriptionId, setCurrentSubscriptionId] = useState(null)
    const [alert, setAlert] = useState(null)
    const [loadDashboard, setLoadDashboard] = useState(false)
    const [messengerId, setMessengerId] = useState('')
    const [showChatbotIdHelp, setShowChatbotIdHelp] = useState(false)
    // const [userTokenData, setUserTokenData] = useState({})
    const [showUpdateSourceModal, setShowUpdateSourceModal] = useState(false)
    const [stripeKey, setStripeKey] = useState(null)
    const [totalPages, setTotalPages] = useState(0)
    const [activeTab, setActiveTab] = useState('account')
    const [showUpgradeModal, setShowUpgradeModal] = useState(false)
    const [isRefreshingCustomer, setIsRefreshingCustomer] = useState(false)
    const [forcePayment, setForcePayment] = useState(false)
    const [currentPageViews, setCurrentPageviews] = useState(null)
    const [currentInvoice, setCurrentInvoice] = useState(null)

    const validPlans = ['plan_FAGM4fB8kraSPm', 'plan_FE4muU3dhdw15R', 'plan_FEoPC8BcCAGxBB', 'plan_FEoQHCr5R4nHLE']
    
    
    const user = firebase.auth().currentUser
    // console.log("user email:", user.email)
    // user.getIdTokenResult().then(data => setUserTokenData(data))
    // const userId = user.uid
    const db = firebase.firestore()
    const userId = user.uid

    useEffect(() => {
        // db.collection('users').doc(userId).onSnapshot(user => {
        //     if(user.exists){
        //         setUserData(user.data())
        //         setIsLoading(false)
        //         getStripeCustomer()
        //     }
        // })
        getStripeCustomer()
    }, [loadDashboard])

    useEffect(() => {
        if(props.match.params.pageId === undefined){
            setStripeKey(config.STRIPE_KEY)
        } else {
            const url = `${config.API_URL}/api/payments/stripe_pk/${props.match.params.pageId}`
            axios
                .get(url)
                .then(response => setStripeKey(response.data.pk_test))
        }
    }, [])

    // useEffect(() => {
    //     console.log("CurrentSubscriptionId updated", currentSubscriptionId)
    // }, [currentSubscriptionId])

    useEffect(() => {
        // console.log("stripeCustomer:>", stripeCustomer)
        const valid = customerHasValidSubscription()
        setForcePayment(!valid)

        if(stripeCustomer !== null && stripeCustomer.id !== undefined){
            axios
                .get(`${config.API_URL}/api/payments/upcoming/${stripeCustomer.id}`)
                .then(response => {
                    // console.log("Upcoming:", response.data)
                    try {
                        setCurrentInvoice(response.data)
                        const line = _.find(response.data.lines.data, { plan: { product: config.STRIPE_PRODUCT_ID }})
                        if(line !== undefined)
                            setCurrentPageviews(line.quantity)
                    } catch(err) {
                        console.error(err)
                    }
                })
                .catch(error => console.error(error))
        }
        // if(stripeCustomer !== null && (stripeCustomer.deleted === true || stripeCustomer.id === undefined))
        //     setForcePayment(true)
        // else
        //     setForcePayment(false)       
    }, [stripeCustomer])

    // useEffect(() => {
    //     console.log("ForcePayment is:", forcePayment)
    // }, [forcePayment])
    
    const customerHasValidSubscription = () => {
        let valid = false
        if(stripeCustomer === null || stripeCustomer.id === undefined || stripeCustomer.deleted === true || stripeCustomer.id === undefined){
            return valid
        }

        const subscriptions = stripeCustomer.subscriptions.data
        subscriptions.map(async subscription => {
            const item = _.find(subscription.items.data, { plan:{ product: config.STRIPE_PRODUCT_ID }})
            if(item !== undefined)
                valid = true
        })

        return valid
    }

    const getStripeCustomer = async() => {
        if(user.email === undefined)
            return
        
        setIsLoadingStripeCustomer(true)
        const customer = await axios.get(`${config.API_URL}/api/payments/customer_by_email/${user.email}`)
        setIsLoadingStripeCustomer(false)
        setStripeCustomer(customer.data)
        setIsLoading(false)
        // console.log("stripeCustomer:", customer.data)
    }

    const refreshStripeCustomer = async () => {
        setIsRefreshingCustomer(true)
        const customer = await axios.get(`${config.API_URL}/api/payments/customer_by_email/${user.email}`)
        setStripeCustomer(customer.data)
        setIsRefreshingCustomer(false)
    }

    const cancelSubscription = async (subscriptionId) => {
        if(subscriptionId === null)
            return
        // console.log("cancelling", subscriptionId)
        
        setIsLoadingStripeCustomer(true)
        const url = `${config.API_URL}/api/payments/subscription/delete/${subscriptionId}`
        const del = await axios.post(url)
        
        setShowCancelModal(false)
        setAlert("Your subscription has been cancelled.")
        getStripeCustomer()
    }

    const reinstateSubscription = async (subscriptionId) => {
        // console.log("reinstating", subscriptionId)
        setIsLoadingStripeCustomer(true)
        const url = `${config.API_URL}/api/payments/subscription/reinstate/${subscriptionId}`
        const reinstate = await axios.post(url)
        
        setShowReinstateModal(false)
        setAlert("Your subscription has been reinstated!")
        getStripeCustomer()
    }

    const CcIcon = brand => {
        let name = ''
        switch(brand.brand){
            case 'Visa':
                name = 'cc visa'
                break
            case 'MasterCard':
                name = 'cc mastercard'
                break
            case 'American Express':
                name = 'cc amex'
                break
            case 'Diners Club':
                name = 'cc diners club'
                break
            case 'Discover':
                name = 'cc discover'
                break
            case 'JCB':
                name = 'cc jcb'
                break
            default:
                name = 'cc stripe'
        }
        return <Icon name={name} style={{fontSize:24, verticalAlign:'middle', color:'gray'}} />
    }

    const cancelThisSubscription = (subscriptionId) => {
        // console.log("cancel", subscriptionId)
        setCurrentSubscriptionId(subscriptionId)
        setShowCancelModal(true)
    }

    const reinstateThisSubscription = (subscriptionId) => {
        // console.log("reinstate", subscriptionId)
        setCurrentSubscriptionId(subscriptionId)
        setShowReinstateModal(true)
    }

    // const getTotalPages = () => {
    //     let thisTotalPages = 0
    //     _.map(stripeCustomer.subscriptions.data, sub => {
    //         _.map(sub.items.data, item => {
    //             // thisTotalPages += sub.plan.transform_usage.divide_by
    //             // console.log("thisTotalPages:", item.quantity, thisTotalPages)
    //             if(item.plan.id === config.STRIPE_PLAN_ID)
    //                 thisTotalPages += item.quantity
    //         })
    //     })
    //     console.log("setTotalPages", thisTotalPages)
    //     setTotalPages(thisTotalPages)
    //     // setTotalPages(10)
    // }

    const CurrentPlanRows = () => {
        // if(isRefreshingCustomer)
        //     return  <Segment style={{minHeight:75}}>
        //                 <Dimmer active inverted>
        //                     <Loader inverted>Loading...</Loader>
        //                 </Dimmer>
        //             </Segment>
        if(stripeCustomer.subscriptions === undefined)
            return <Fragment></Fragment>

        return _.map(stripeCustomer.subscriptions.data, sub => {
            return _.map(sub.items.data, item => {
                // if(item.plan.id !== config.STRIPE_PLAN_ID)
                //     return <Fragment></Fragment>

                return (
                    <Table.Row key={item.plan.id}>
                        <Table.Cell>
                            {/* <strong>{item.plan.nickname} ${(item.plan.amount/100).toFixed(2)}/{item.plan.interval}</strong> */}
                            <strong>{item.plan.metadata.description}</strong>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                            {/* {sub.plan.transform_usage.divide_by} */}
                            {/* {sub.quantity} */}
                            {currentPageViews === null 
                                ? <Icon loading name='spinner' />
                                : currentPageViews
                            }
                        </Table.Cell>
                        {sub.cancel_at === null 
                            ?
                                <React.Fragment>
                                    <Table.Cell>
                                        {currentInvoice !== null &&
                                            <Fragment>${currentInvoice.amount_due/100} on </Fragment>
                                        }
                                        {moment(sub.current_period_end*1000).format('MMM D, YYYY')} 
                                    </Table.Cell>
                                    <Table.Cell>
                                        {stripeCustomer.sources.data[0] !== undefined &&
                                            <Fragment>
                                                <CcIcon brand={stripeCustomer.sources.data[0].brand} /> last four {stripeCustomer.sources.data[0].last4}
                                            </Fragment>
                                        }
                                        <button onClick={() => setShowUpdateSourceModal(true)} style={{fontSize:'0.8em', display:'inline', border:'1px solid gray', color:'gray', borderRadius:20, padding:'3px 12px 3px 9px', cursor:'pointer', marginLeft:15}}>
                                            <Icon name="edit" />
                                            Update
                                        </button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => cancelThisSubscription(sub.id)} style={{width:90, fontSize:'0.8em', display:'inline', border:'1px solid gray', color:'gray', borderRadius:20, padding:'3px 12px 3px 9px', marginLeft:15, cursor:'pointer'}}>
                                            <Icon name="times" />
                                            Cancel
                                        </button>
                                    </Table.Cell>
                                </React.Fragment>
                            :
                                <React.Fragment>
                                    <Table.Cell style={{color:'red'}}>
                                        Cancels on {moment(sub.cancel_at*1000).format('MMM D, YYYY')}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {stripeCustomer.sources.data[0] !== undefined &&
                                            <Fragment>
                                                <CcIcon brand={stripeCustomer.sources.data[0].brand} /> last four {stripeCustomer.sources.data[0].last4}
                                            </Fragment>
                                        }
                                        <button onClick={() => setShowUpdateSourceModal(true)} style={{fontSize:'0.8em', display:'inline', border:'1px solid gray', color:'gray', borderRadius:20, padding:'3px 12px 3px 9px', cursor:'pointer', marginLeft:15}}>
                                            <Icon name="edit" />
                                            Update
                                        </button>
                                    </Table.Cell>
                                    <Table.Cell>
                                        <button onClick={() => reinstateThisSubscription(sub.id)} style={{width:90, fontSize:'0.8em', display:'inline', border:'1px solid green', color:'green', borderRadius:20, padding:'3px 12px 3px 9px', marginLeft:15, cursor:'pointer'}}>
                                            <Icon name="check" />
                                            Reinstate
                                        </button>
                                    </Table.Cell>
                                </React.Fragment>
                        }
                    </Table.Row>
                )
            })
        })
    }
    const CurrentPlan = () => {
        return (
            <Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Plan Name</Table.HeaderCell>
                        <Table.HeaderCell>Current Pageviews</Table.HeaderCell>
                        <Table.HeaderCell>Next Billing Date</Table.HeaderCell>
                        <Table.HeaderCell>Card</Table.HeaderCell>
                        <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <CurrentPlanRows />
                </Table.Body>
            </Table>
        )
    }

    const Alert = () => {
        if(alert === null)
            return null

        return (
            <Message positive>
                <Message.Header>{alert}</Message.Header>
            </Message>
        )
    }

    if(userData === {}){
        return (
            <div style={{textAlign: 'center', padding:100}}>
                <Button size='large' onClick={() => setLoadDashboard(true)}>Load Account</Button>
            </div>
        )
    }

    const upgradeOptions = () => {
        const options = []
        for(let i = 11; i <= 100; i++){
            options.push({key:i, value:i, text:i})
        }
        return options
    }

    // const YourSubscription = () => {
    //     const {next_invoice} = stripeCustomer
    //     return (
    //         <Grid stackable>
    //             <Grid.Row columns={2}>
    //                 <Grid.Column>
    //                     <Header as='h2'>
    //                         <Icon name='dollar' color='green' />
    //                         {next_invoice !== undefined &&
    //                             <React.Fragment>
    //                                 {subscriptions[0].cancel_at_period_end &&
    //                                     <Header.Content>
    //                                         Next Payment: None
    //                                         <Header.Subheader>
    //                                             Subscription end date: {moment(subscriptions[0].current_period_end*1000).format('MMMM DD, YYYY')} 
    //                                             <Label color='green' size='mini' onClick={() => this.setState({confirm_reactivate:true})} style={{cursor:'pointer'}}>Reactivate</Label>
    //                                         </Header.Subheader>
    //                                     </Header.Content>
    //                                 }
    //                                 {!subscriptions[0].cancel_at_period_end &&
    //                                     <Header.Content>
    //                                         Next Payment: ${(next_invoice.amount_due/100).toLocaleString()}
    //                                         <Header.Subheader>
    //                                             Next payment date: {moment(next_invoice.date*1000).format('MMMM DD, YYYY')} 
    //                                             <Label color='red' size='mini' onClick={() => this.setState({confirm_cancel:true})} style={{cursor:'pointer'}}>Cancel</Label>
    //                                         </Header.Subheader>
    //                                     </Header.Content>
    //                                 }
    //                             </React.Fragment>
    //                         }
    //                     </Header>
    //                 </Grid.Column>
    //                 <Grid.Column>
    //                     <Header as='h2'>
    //                         <Icon name='settings' />
    //                         <Header.Content>
    //                             Chadderbots
    //                             <Header.Subheader>Using {Object.keys(connections).length} of {AccountStore.maxChadderbots}</Header.Subheader>
    //                         </Header.Content>
    //                     </Header>
    //                 </Grid.Column>
    //             </Grid.Row>
    //         </Grid>
    //     )
    // }
    if(isLoading || isLoadingStripeCustomer)
        return <Segment style={{minHeight:200}}>
            <Dimmer active inverted>
                <Loader inverted>Loading...</Loader>
            </Dimmer>
        </Segment>

    return (
        <div>
            {/* <Menu pointing secondary>
                <Menu.Item
                    name='account'
                    active={activeTab === 'account'}
                    onClick={() => setActiveTab('account')}
                />
                <Menu.Item
                    name='templates'
                    active={activeTab === 'templates'}
                    onClick={() => setActiveTab('templates')}
                />
                <Menu.Menu>
                    <Menu.Item
                    name='training'
                    active={activeTab === 'training'}
                    onClick={() => setActiveTab('training')}
                    />
                </Menu.Menu>
            </Menu> */}
            {activeTab === 'templates' && <Templates />}
            {activeTab === 'training' && <Training />}
            {activeTab === 'account' &&
            <React.Fragment>
                {/* <h1>Account</h1> */}
                <Alert />
                <Segment basic loading={ isLoading || isLoadingStripeCustomer }>
                    {!isLoading &&
                        <>
                            <h2>Your Subscription
                                {/* <Button floated='right' positive onClick={() => setShowUpgradeModal(true)}>Upgrade</Button> */}
                            </h2>
                            {/* { moment().format("M") < 12 && moment().format("Y") <= 2019 &&
                                <Message icon="check circle outline" success header="Free until December 1st!" />
                            } */}
                            {/* {console.log("currentSubscriptionId", currentSubscriptionId, stripeCustomer, forcePayment)} */}
                            {/* {!forcePayment && currentSubscriptionId !== 0 && currentSubscriptionId !== null && stripeCustomer !== null &&  */}
                            {!forcePayment && stripeCustomer !== null && 
                                <Fragment>
                                    <CurrentPlan />
                                    <Connections stripeCustomer={stripeCustomer} onChange={() => refreshStripeCustomer()} />
                                    {/* <Resources /> */}
                                    {/* <ChadderbotConnections /> */}
                                </Fragment>
                            }
                            { (forcePayment === true) && 
                                <>
                                { stripeKey !== null &&
                                    <Grid stackable>
                                        <Grid.Row>
                                            <Grid.Column width={8}>
                                                <StripeProvider apiKey={config.STRIPE_KEY}>
                                                    <Elements>
                                                        <Payment 
                                                            planOrder={props.match.params.planOrder}
                                                            pageId={props.match.params.pageId} 
                                                            onSuccess={() => getStripeCustomer()}
                                                        />
                                                    </Elements>
                                                </StripeProvider>
                                            </Grid.Column>
                                            <Grid.Column width={8} style={{padding:'10%'}}>
                                                <Image src={`/images/undraw_Credit_card_3ed6.svg`} style={{marginTop:-100, zIndex:-1, opacity:0.5}} />
                                            </Grid.Column>
                                        </Grid.Row>
                                        
                                        {/* <div style={{clear:'both'}}></div> */}
                                    </Grid>
                                }
                                </>
                            }

                            
                        </>
                    }
                </Segment>
                
                <Modal open={showEmailModal} centered={true} size='mini' closeIcon onClose={() => setShowEmailModal(false)} >
                    <Modal.Header>Verification Email Sent</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Please check your email and respond by clicking the link.</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button onClick={() => setShowEmailModal(false)}>Resend</Button> */}
                        <Button onClick={() => setShowEmailModal(false)} positive icon='checkmark' labelPosition='right' content='Done' />
                    </Modal.Actions>
                </Modal>

                <Modal size='tiny' open={showCancelModal} centered={true} closeIcon onClose={() => setShowCancelModal(false)}>
                    <Modal.Header>Cancel Subscription</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Are you sure? This subscription will be cancelled at the end of the billing cycle. </p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowCancelModal(false)}>Keep my Account</Button>
                        <Button onClick={
                            () => cancelSubscription(currentSubscriptionId)} 
                            negative icon='checkmark' labelPosition='right' content='Cancel Subscription'
                            loading={isLoadingStripeCustomer}
                        />
                    </Modal.Actions>
                </Modal>

                <Modal open={showReinstateModal} centered={true} closeIcon onClose={() => setShowCancelModal(false)}>
                    <Modal.Header>Reinstate Subscription</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Good news! You can reinstate this subscription so that it won't cancel at the end of the billing period.</p>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowReinstateModal(false)}>No Thanks</Button>
                        <Button positive 
                            onClick={() => reinstateSubscription(currentSubscriptionId)} 
                            loading={isLoadingStripeCustomer} 
                            icon='checkmark' labelPosition='right' content='Reinstate Subscription' 
                        />
                    </Modal.Actions>
                </Modal>

                <Modal open={showUpdateSourceModal} centered={true} closeIcon onClose={() => setShowUpdateSourceModal(false)} size='tiny'>
                    <Modal.Header>Update Credit Card</Modal.Header>
                    <Modal.Content>
                        <StripeProvider apiKey={config.STRIPE_KEY}>
                            <Elements>
                                <Payment 
                                    userData={userData} 
                                    updateOnly={true} 
                                    onSuccess={() => getStripeCustomer()} 
                                />
                            </Elements>
                        </StripeProvider>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowUpdateSourceModal(false)}>Close</Button>
                    </Modal.Actions>
                </Modal>

                <Modal open={showUpgradeModal} centered={true} closeIcon onClose={() => setShowUpgradeModal(false)}>
                    <Modal.Header>Upgrade Subscription</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <p>Options...{totalPages}</p>
                            <Select options={[
                                {key:'1', value:'1', text:'1'},
                                {key:'5', value:'5', text:'5'},
                                {key:'10', value:'10', text:'10'},
                                ...upgradeOptions()
                            ]} />
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowUpgradeModal(false)}>Cancel</Button>
                        <Button onClick={
                            () => setShowUpgradeModal(false)}
                            positive icon='checkmark' labelPosition='right' content='Upgrade Subscription'
                            loading={isLoadingStripeCustomer}
                        />
                    </Modal.Actions>
                </Modal>
                {/* <Button onClick={() => {
                    const url = `${config.API_URL}/api/manychat/getMessengerProfile`
                    axios.get(url)
                        .then(response => console.log("Test", response))
                }}>Get Messenger Profile</Button>
                <Button onClick={() => {
                    const url = `${config.API_URL}/api/manychat/updateMessengerProfile`
                    axios.get(url)
                        .then(response => console.log("Test 2", response))
                }}>Update Messenger Profile</Button> */}
            </React.Fragment>
            }
        </div>
    )

}