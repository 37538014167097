import React from 'react';
import { Link } from 'react-router-dom'
import { Menu, Container, Image } from 'semantic-ui-react'


export default function Foot(props) {

    if (true) {
        return (
            <>
            <Menu style={{backgroundColor:'#000', marginBottom:0, marginTop:50, borderRadius:0}} inverted>
                <Container fluid={props.fluid}> 
                    <Menu.Item header>
                        Copyright&copy;
                        <Link to="/">
                            {/* <Image src='/ibuumerang-icon.png' avatar /> */}
                            <Image src='/images/ChatbotMediaLogo.png' verticalAlign='middle' style={{maxHeight:23, marginRight:15}} />
                        </Link>
                        <p> | <a href="/privacy" style={{marginLeft:15}}>Privacy Policy</a>
                        {/* {' '}| <a href="https://icons8.com" target="_blank">Illustrations by Ouch.pics</a> */}
                        </p>
                    </Menu.Item>
                </Container>
            </Menu>
            
            </>

        )
    }
    
}