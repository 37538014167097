import React, { useState, useEffect, Fragment } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase';
import { Image, Menu, Container, Dropdown, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
// import { CustomConsole } from '@jest/console';


export default function Head(props) {

    const [isSignedIn, setIsSignedIn] = useState(false)

    useEffect(() => {
        if(firebase.auth().currentUser)
            setIsSignedIn(true)
    })  

    // const logOut = () => {
    //     console.log("Handle LogOut")
    //     firebase.auth().signOut()
    //     setIsSignedIn(false)
    // }
    const logIn = props => {
        console.log(props)
        props.history.push("/login")
    }

    const { userTokenData } = props
    
    if (true) {
        return (
            <Menu style={{backgroundColor:'#0c71c3', marginBottom:0, borderRadius:0}} inverted fixed='top'>
                <Container fluid={props.fluid}> 
                    <Menu.Item header>
                        <Link to="/">
                            <Image src='/images/ChatbotMediaLogo.png' verticalAlign='middle' style={{maxHeight:30, marginRight:7}} />
                        </Link>
                    </Menu.Item>
                    { isSignedIn
                        ?
                        <Fragment>
                            <Menu.Item name='account' position='right'>
                                <Link to="/account" icon='user'><Icon name='user' /> Account</Link>
                            </Menu.Item>
                            <Menu.Item name='logout' onClick={props.logOut} icon="log out" />
                        </Fragment>
                        :
                        <Menu.Item name='login' position='right'><Link to="/login"><Icon name='user' /> Login</Link></Menu.Item>
                    }
                </Container>
            </Menu>
        )
    }
    
}