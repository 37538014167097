import React, { useState, useEffect, Fragment } from "react"
// import firebase from 'firebase'
import _ from 'lodash'
import { Menu, TextArea, Grid, Label, Popup, Container, Segment, Message, Button, Icon, Modal, Header, Form, Input, Checkbox, Table, Confirm, Dropdown } from 'semantic-ui-react'
import axios from 'axios'
import config from '../config'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {CopyToClipboard} from 'react-copy-to-clipboard'
// import ChadderbotConnections from './ChadderbotConnections'

toast.configure({
    autoClose: 5000,
    draggable: false,
});

export default function GenerateUrl(props){

    const [isLoadingTags, setIsLoadingTags] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')
    const [pageOptions, setPageOptions] = useState([])
    const [pageTags, setPageTags] = useState([])
    const [urlStartedTag, setUrlStartedTag] = useState('[STARTED TAG]')
    const [urlFinishedTag, setUrlFinishedTag] = useState('[FINISHED TAG]')
    const [videoEndTag, setVideoEndTag] = useState('[END TAG]')
    const [urlConnection, setUrlConnection] = useState({})
    const [urlPageId, setUrlPageId] = useState('[PAGE ID]')
    const [urlVideoId, setUrlVideoId] = useState('')
    const [pageId, setPageId] = useState(props.pageId)
    

    useEffect(() => {
        // setVideoUrl(`${window.location.origin}/video/${urlVideoId}?page_id=${urlPageId}&tags=${urlStartedTag},${urlFinishedTag},${videoEndTag}&user_id=[USER ID GOES HERE]`)
        setVideoUrl(`https://video.chatbot.media/video/${urlVideoId}?page_id=${urlPageId}&tags=${urlStartedTag},${urlFinishedTag},${videoEndTag}&user_id=[USER ID GOES HERE]`)
    }, [urlVideoId, urlConnection, urlStartedTag, urlFinishedTag, videoEndTag])

    useEffect(() => {
        updateUrlPage(pageId)
    }, [pageId])
    
    const helpLabel = (label, help) => (
        <span style={{fontWeight:'bold'}}>
            {label} 
            <Popup 
                trigger={<Icon name='question circle' color='grey' style={{marginLeft:5}} />} 
                content={help} />
        </span>
    )

    const updateUrlPage = async (pageId) => {
        if(pageId === null || pageId === undefined)
            return

        setPageTags([])
        setUrlStartedTag('[STARTED TAG]')
        setUrlFinishedTag('[FINISHED TAG]')
        const connection = _.find(props.connections, {id: parseInt(pageId)})
        setUrlConnection(connection)
        setUrlPageId(pageId)

        //getTags
        const url = `${config.API_URL}/api/manychat/getTags`
        setIsLoadingTags(true)
        const response = await axios.post(url, {
            pageId: pageId
        })
        setIsLoadingTags(false)
        const tags = _.sortBy(response.data.data, ['name'])
        // console.log("tags", tags)
        const newTags = tags.map(tag => ({
            key: tag.id,
            text: tag.name,
            value: tag.name
        }))
        setPageTags(newTags)
    }

    const createTag = async (tag) => {
        const url = `${config.API_URL}/api/manychat/createTag`
        setIsLoadingTags(true)
        const response = await axios.post(url, {
            pageId: urlPageId,
            tag
        })
        setIsLoadingTags(false)
        // console.log(response)
        if(response.data.status === 'success'){
            const newTag = response.data.data.tag
            // console.log("newTag", newTag)
            const newTags = [
                { key: newTag.id, text: newTag.name, value: newTag.name },
                ...pageTags
            ]
            // console.log("oldTags", pageTags)
            // console.log("newTags", newTags)
            setPageTags(newTags)
        } else {
            toast.error(`Error: ${response.data.message}`)
        }
    }

    return (
        <Fragment>
            <h2>Generate URL</h2>
            <Segment loading={isLoadingTags}>
                <Form>
                    {/* <Form.Select
                        fluid
                        label={helpLabel("Page", "Select the page/chatbot you want to insert this video link into.")}
                        options={props.pageOptions}
                        placeholder='Select Page'
                        onChange={(e, data) => updateUrlPage(data.value)}
                    /> */}
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <Form.Input type="text" fluid
                                label={helpLabel("Youtube Video ID", "Enter the youtube video ID for the video you want to display.")}
                                value={urlVideoId}
                                onChange={e => setUrlVideoId(e.target.value)}
                                disabled={false}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown 
                                placeholder='Started Watching Tag' 
                                label={helpLabel("Started Watching Tag", "Select the tag you want to add to the user when they start watching the video.")}
                                search selection allowAdditions
                                options={pageTags} 
                                onChange={(e, data) => setUrlStartedTag(data.value)}
                                disabled={isLoadingTags}
                                loading={isLoadingTags}
                                additionLabel={<span><Icon name="add" color='blue' /> Add </span>}
                                onAddItem={(e, data) => createTag(data.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown 
                                placeholder='Finished Watching Tag' 
                                label={helpLabel("Finished Watching Tag", "Select the tag you want to add to the user when they finish watching at least 95% of the entire video.")}
                                search selection allowAdditions
                                options={pageTags} 
                                onChange={(e, data) => setUrlFinishedTag(data.value)}
                                disabled={isLoadingTags}
                                loading={isLoadingTags}
                                additionLabel={<span><Icon name="add" color='blue' /> Add </span>}
                                onAddItem={(e, data) => createTag(data.value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Dropdown 
                                placeholder='Video End Tag' 
                                label={helpLabel("Video End Tag", "Select the tag you want to add to the user when they reach the end of the video. This will fire regardless of if they watch the entire video, meaining, even if they skip to the end.")}
                                search selection allowAdditions
                                options={pageTags} 
                                onChange={(e, data) => setVideoEndTag(data.value)}
                                disabled={isLoadingTags}
                                loading={isLoadingTags}
                                additionLabel={<span><Icon name="add" color='blue' /> Add </span>}
                                onAddItem={(e, data) => createTag(data.value)}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths='equal'>
                        <Form.Field>
                            <label>Video URL</label>
                            <Input value={videoUrl} type="text" fluid />
                            <CopyToClipboard text={videoUrl} onCopy={() => console.log("copied")}>
                                <Button icon style={{marginTop:20}}><Icon name="copy" /> Copy</Button>
                            </CopyToClipboard>
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <p>If you add a User Custom Field called <strong>ChatbotMedia: Last Video Watched</strong>, ChatbotMedia will populate it with the title and URL of the last video the subscriber interacts with, handy for admin notifications.</p>
        </Fragment>
    )
}