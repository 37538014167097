import React, { Fragment, useState } from 'react'
import { Segment, Header, Icon, Modal, Button } from 'semantic-ui-react'
import YouTube from 'react-youtube'

export default function Resources(props) {

    const [showVideoModal, setShowVideoModal] = useState(false)
    const [currentVideoId, setCurrentVideoId] = useState(null)

    const opts = {
        playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0,
            rel: 0
        }
    }

    return (
        <Fragment>
            <h2>Resources</h2>
            <Segment>
                <Header>Template</Header>
                <p>Get the demo ManyChat template <a href="https://manychat.com/template/3d0222f20237901839b82bcd6237fc8ba101a131" target="_blank">here <Icon name="external" /></a></p>

                <Header>Tutorials</Header>
                <p><a href="javascript:;" onClick={() => {
                    setCurrentVideoId('RsEqrB5aV1U')
                    setShowVideoModal('true')
                }}>Connecting a Page and Generating a URL</a></p>

                <p><a href="javascript:;" onClick={() => {
                    setCurrentVideoId('BvIlCzgiB90')
                    setShowVideoModal('true')
                }}>Adding ManyChat Automation Rules</a></p>
            </Segment>

            {showVideoModal && currentVideoId !== null &&
                <Modal onClose={() => setShowVideoModal(false)} open={showVideoModal} closeOnDimmerClick={true} closeOnDocumentClick={true} closeOnEscape={true} closeIcon={true}>
                    <Modal.Header>Tutorial</Modal.Header>
                    <Modal.Content>
                        <YouTube
                            videoId={currentVideoId}
                            opts={opts}
                            containerClassName="resp-container"
                            className="resp-iframe"
                        />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setShowVideoModal(false)}>
                            Close
                        </Button>
                    </Modal.Actions>
                </Modal>
            }
        </Fragment>
    )
}