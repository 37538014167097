import React, { useState, useEffect } from "react"
// import Head from './Head'
import { Segment, Header, Image, Grid, Button, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import firebase from 'firebase';
// import YouTube from 'react-youtube'
// import Login from './Login'
// import _ from 'lodash'


export default function Home(props) {
    
    useEffect(() => {
        // setTimeout(() => loadServiceBot(), 1000)
        // window.location.href = "https://chatbotmedia.club"
    }, [])

    // const loadServiceBot = () => {
    //     if(window.servicebotSettings !== undefined)
    //         return
        
    //     window.servicebotSettings = {
    //         servicebot_id: "4ONDvoyAcDoj", // LIVE & TEST MODE IDs ARE DIFFERENT
    //         service: "ChatbotMedia Platform",
    //         // tier: "Starter",
    //         // interval: "month",
    //         customer_id: "cus_G020c5QmdNfG69", // REPLACE WITH USER'S STRIPE CUSTOMER ID,
    //         options: {
    //             forceCard: true,
    //             card: {
    //                 hideAddress: true,
    //                 hideCountryPostal: true,
    //             },
    //             quantity:1,
    //             metadata:{uid2:"abc222"}
    //         },
    //         handleResponse: async function({event, response}){
    //             console.log("handleResponse", event, response)
    //             if(event === "create_subscription" || event === "resubscribe"){
    //                 console.log("Activating account!", response);
    //             }
    //         } 
    //     };

    //     (function () { 
    //         var s = document.createElement('script'); 
    //         s.src = 'https://js.servicebot.io/embeds/servicebot-billing-settings-embed.js'; 
    //         s.async = true; 
    //         s.type = 'text/javascript'; 
    //         var x = document.getElementsByTagName('script')[0]; 
    //         x.parentNode.insertBefore(s, x); 
    //     })();
    // }

    const HomepageHeading = ({ mobile }) => (
        <div style={{ backgroundColor:'#0c71c3', paddingTop:'40vh', paddingLeft:'5%', paddingRight:'5%', height: '100vh', marginBottom:-50, textAlign:'center'}}>
            {/* <Container text> */}
                <Image src="/images/ChatbotMediaLogo.png" size='large' centered />
                {firebase.auth().currentUser
                    ?
                    <Link to='/account'>
                        <Button size='large' color='green' style={{marginTop:30}}>Account <Icon name="user" style={{marginLeft:10}} /></Button>
                    </Link>
                    :
                    <Link to='/account'>
                        <Button size='large' color='green' style={{marginTop:30}}>Log In <Icon name="user" style={{marginLeft:10}} /></Button>
                    </Link>
                }
                
                <a href="https://chatbotmedia.com">
                    <Button size='large' color='red' style={{marginTop:30, marginLeft:10}}>Learn More <Icon name="external" style={{marginLeft:10}} /></Button>
                </a>
                {/* <Header
                    as="h1"
                    inverted
                    style={{
                        fontSize: mobile ? "2em" : "4em",
                        fontWeight: "normal",
                        marginBottom: 0,
                        fontFamily: "Poppins, Helvetica"
                    }}
                >
                    ChatbotMedia
                    <Header.Subheader>BETA</Header.Subheader>
                </Header> */}
            {/* </Container> */}
        </div>
    )

    // return null

    return (
        <HomepageHeading />
        // <Grid>
        //     <Grid.Row columns={2} verticalAlign='middle'>
        //         <Grid.Column>
        //             <Header as='h1'>Extend ManyChat with Youtube Videos</Header>
        //             <Header as='h2' color='grey'>Trigger ManyChat events based on Youtube videos watched by subscribers.</Header>
        //         </Grid.Column>
        //         <Grid.Column>
        //             <Image src="/images/Artificial_intelligence_oyxx.svg" />
        //         </Grid.Column>
        //     </Grid.Row>
        //     <Grid.Row>
        //         <Grid.Column>
        //             <div id="servicebot-subscription-portal"></div>
        //         </Grid.Column>
        //     </Grid.Row>
        // </Grid>
        
    )
}