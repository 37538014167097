import React, {useState, useEffect} from 'react'
import './App.css'
// import * as firebase from 'firebase'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Home from './components/Home'
// import { Container } from 'semantic-ui-react'
import Video from './components/Video'
// import Leaders from './components/Leaders'
import Login from './components/Login'
import Loading from './components/Loading'
import Dashboard from './components/Dashboard'
import Prospector from './components/Prospector'
// import Activity from './components/Activity'
import Head from './components/Head'
import Foot from './components/Foot'
import { Container } from 'semantic-ui-react'
import Privacy from './components/Privacy'
import ContestRules from './components/ContestRules'
// import Admin from './components/Admin'
import Unauthorized from './components/Unauthorized'
// import BusinessTestimonials from './pages/BusinessTestimonials'
// import Customers from './components/admin/Customers'
import Signup from './components/Signup'
import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyDcPvozANDSl4r8UNShia5vK4rbrDvj9ik",
    authDomain: "chatbotmedia1.firebaseapp.com",
    databaseURL: "https://chatbotmedia1.firebaseio.com",
    projectId: "chatbotmedia1",
    storageBucket: "chatbotmedia1.appspot.com",
    messagingSenderId: "966700881026",
    appId: "1:966700881026:web:42bb9e865719c37bc6909b"
}

firebase.initializeApp(firebaseConfig)


function App() {

    const [user, setUser] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [userTokenData, setUserTokenData] = useState({})
    const db = firebase.firestore()
        
    const RequireAuth = (ComposedComponent) => {
        if(isLoading)
            return Loading
        if(user !== null)
            return ComposedComponent
        return Login
    }

    const RequireAdmin = (ComposedComponent) => {
        if(isLoading)
            return Loading
        if(userTokenData.claims && userTokenData.claims.admin)
            return ComposedComponent
        return Unauthorized
    }

    // useEffect(() => {
    //     console.log("userTokenData >>", userTokenData)
    // }, [userTokenData])
    
    useEffect(() => {
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                console.log("user is signed in", user)
                setUser(user)
                // getUserData(user)
                user.getIdTokenResult().then(data => setUserTokenData(data))
            }
            setIsLoading(false)
        })
    }, [])


    // const getUserData = user => {
    //     db.collection('users').doc(user.uid).get()
    //         .then(doc => {
    //             if (!doc.exists) {
    //                 addUser(user)
    //             }
    //         })
    //         .catch(err => {
    //             console.error('Error getting document', err);
    //         });
    // }
    
    // const addUser = user => {
    //     var data = {
    //         phoneNumber: user.phoneNumber
    //     }
    //     var setDoc = db.collection('users').doc(user.uid).set(data)
    //         .then(result => console.log("setDoc", result))
    // }

    const logOut = () => {
        firebase.auth().signOut()
        setUser(null)   
    }

    const RouteWithHead = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props => (
            <div>
                <Head user={user} logOut={logOut} userTokenData={userTokenData} fluid={false} />
                <Container style={{marginTop:80, minHeight:'90vh'}}>
                    <Component {...props} />
                </Container>
                <Foot fluid={true} />
            </div>
        )}/>
    )

    const RouteFullWidth = ({ component: Component, ...rest }) => (
        <Route {...rest} render={props => (
            <div>
                <Head user={user} logOut={logOut} userTokenData={userTokenData} fluid={true} />
                <Component {...props} />
                <Foot fluid={true} />
            </div>
        )}/>
    )
    

    return (
        <div className="page">
            <Router>
                <React.Fragment>
                    <RouteFullWidth exact path='/' component={Home} />
                    <Route exact path='/video/:videoId' component={Video} />
                    <RouteWithHead exact path='/account/:pageId' component={RequireAuth(Dashboard)} />
                    <RouteFullWidth exact path='/privacy' component={Privacy} />
                    <RouteFullWidth exact path='/contest-rules' component={ContestRules} />
                    <RouteWithHead exact path='/login' component={Login} />
                    <RouteWithHead exact path='/account' component={RequireAuth(Dashboard)} />
                    <RouteWithHead exact path='/prospector' component={RequireAuth(Prospector)} />
                    <RouteWithHead exact path='/signup/:planOrder' component={Signup} />
                    {/* <RouteFullWidth exact path='/' component={Home} />
                    <RouteFullWidth exact path='/home' component={Home} />
                    <RouteFullWidth exact path='/leaders' component={Leaders} />
                    <Route exact path='/account/activity/:subscriberId' component={Activity} />
                    <RouteWithHead exact path='/admin' component={RequireAdmin(Admin)} />
                    <RouteFullWidth exact path='/customers' component={RequireAdmin(Customers)} />
                    <Route exact path='/testimonials/business' component={BusinessTestimonials} /> */}
                    {/* <RouteWithHead exact path='/login/:email' component={Login} /> */}
                    {/* <RouteWithHead exact path='/findnumber' component={RequireAuth(BuyNumber)} /> */}
                    {/* <RouteWithHead exact path='/admin/phone/:phoneNumber' component={RequireAdmin(AdminPhone)} />
                    <RouteWithHead exact path='/admin/messenger/:messengerId' component={RequireAdmin(AdminMessenger)} /> */}
                    {/* <Route exact path='/l/:base62contactId' component={Landing} /> */}
                    {/* <Route exact path='/v/:base62contactId' component={Videos} /> */}
                    {/* <Route exact path='/v/:videoId/:base62contactId' component={Videos} /> */}
                </React.Fragment>
            </Router>
        </div>
    )
}

export default App;
