import React, { Fragment } from 'react'
import firebase from 'firebase'
import LoginInline from './LoginInline'
import Dashboard from './Dashboard'
import { Segment, Header } from 'semantic-ui-react'

export default function Signup(props) {

    const login = () => (
        <Segment textAlign='center' padded='very'>
            <Header>Sign in with Facebook to Continue</Header>
            <LoginInline />
        </Segment>
    )


    console.log("currentUser", firebase.auth().currentUser)
    if(firebase.auth().currentUser === null)
        return login()

    return (
        <Dashboard match={props.match} />
    )
}