import React, {Component, Fragment} from 'react'
import firebase from 'firebase'
import {CardElement, injectStripe} from 'react-stripe-elements'
import { Segment, Dropdown, Message, Input, Button, Modal } from 'semantic-ui-react'
import axios from 'axios'
import config from '../config'
import '../styles/main.css'
import _ from 'lodash'
// import Login from './Login'
import Countdown from './Countdown'
import moment from 'moment'
import TosModal from './TosModal'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { database } from 'firebase-admin'

toast.configure({
    autoClose: 5000,
    draggable: false,
});

const qs = (key) => {
    key = key.replace(/[*+?^$.\[\]{}()|\\\/]/g, "\\$&"); // escape RegEx meta chars
    var match = window.location.search.match(new RegExp("[?&]"+key+"=([^&]+)(&|$)"));
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

class Payment extends Component {
    constructor(props) {
        super(props);
        this.submitPayment = this.submitPayment.bind(this);
        
        // const free_until = moment("December 1, 2019").format("X")
        // const free_until = moment().add(7, 'days').format("X")
        // const trial_end = moment().format('X') < free_until ? free_until : 'now'
        // const trial_end = 'now'
        // const trial_end = qs('trial') === "7day" ? free_until : 'now'
        
        this.state = {
            isLoading: false,
            error: '',
            selectedPlan: '',
            currentPlans: {},
            showCouponModal: false,
            couponCode: '',
            couponError: '',
            couponSuccess: false,
            name: '',
            email: '',
            showLoginModal: false,
            updateSuccess: false,
            showTosModal: false,
            showAlert: false,
            alertText: '',
            tosError: false,
            plans: [],
            accordionTabActive: false,
            planSelectOptions: []
        }

        this.applyCoupon = this.applyCoupon.bind(this)
        this.submitUpdate = this.submitUpdate.bind(this)
    }

    componentDidMount(){
        const creationTime = firebase.auth().currentUser.metadata.creationTime
        const exp = moment(creationTime).add(24, 'hours')
        const now = moment()

        // console.log("props:", this.props)

        const url = `${config.API_URL}/api/payments/plans/${config.STRIPE_PRODUCT_ID}`
        this.setState({ isLoading: true })
        axios.get(url)
            .then(response => {
                // console.log("product:>",response.data.data)
                const planSelectOptions = _.map(_.filter(_.sortBy(response.data.data, ['metadata.order']), plan => plan.metadata.status === 'publish'), plan => {
                    return {
                        key: plan.id,
                        text: plan.metadata.description,
                        value: plan.id
                    }
                })
                this.setState({planSelectOptions})

                if(planSelectOptions.length === 1){
                    this.setState({selectedPlan: _.find(response.data.data, {id: planSelectOptions[0].key}), selectedPlanKey: planSelectOptions[0].key})
                }

                this.setState({ plans: response.data.data, isLoading: false })
                if(this.props.planOrder === undefined){
                    const default_plan = _.find(response.data.data, {metadata: {default: 'true', status: 'publish'}})
                    if(default_plan !== undefined)
                        this.setState({selectedPlanKey: default_plan.id, selectedPlan: default_plan})
                } else {
                    const linked_plan = _.find(response.data.data, {metadata: {order: this.props.planOrder, status: 'publish'}})
                    if(linked_plan !== undefined)
                        this.setState({selectedPlanKey: linked_plan.id, selectedPlan: linked_plan})
                }
                
                // console.log("planSelectOptions:", planSelectOptions, planSelectOptions[0].text)
            })
            .catch(err => {
                console.error(err)
                this.setState({ isLoading: false })
            })
    }

    async submitPayment(ev) {
        if(this.state.tosChecked !== true){
            this.setState({
                alertText: 'Please accept the terms of service to continue.',
                showAlert: true,
                tosError: true
            })
            return
        }

        this.setState({isLoading: true, error: ''})
        let stripe_request = await this.props.stripe.createToken({name: "Name"});
        // console.log("stripe request", stripe_request)

        let { token, error } = stripe_request

        if(!token){
            // this.setState({ isLoading: false, error: error.message })
            toast.error(error.message)
            this.setState({isLoading: false})
            return
        }

        const url = `${config.API_URL}/api/payments/charge`
        const { email, uid, displayName } = firebase.auth().currentUser

        const free_until = moment().add(7, 'days').format("X")
        const trial_end = qs('trial') === "7day" ? free_until : 'now'

        const body = {
            "token": token.id,
            "uid": uid,
            "email": email,
            "displayName": displayName,
            "plan": this.state.selectedPlan,
            "trial_end": trial_end,
        }
        // axios.post(url, body, { headers: { "Authorization": `Bearer ${token}` }})
        axios.post(url, body)
            .then(res => {
                // console.log("charge res:", res)
                this.setState({isLoading: false})
                if(res.data.message === undefined)
                    this.props.onSuccess()
                else
                    toast.error(res.data.message)
            })
            .catch(err => {
                console.error(err)
                this.setState({isLoading: false})
            })
    }

    async submitFreeAccount(){
        this.setState({isLoading: true, error: ''})
        
        const url = `${config.API_URL}/api/payments/charge`
        const { email, uid, displayName } = firebase.auth().currentUser

        const body = {
            "uid": uid,
            "email": email,
            "displayName": displayName,
            "plan": this.state.selectedPlan,
            "coupon_code": this.state.couponCode
        }
        // axios.post(url, body, { headers: { "Authorization": `Bearer ${token}` }})
        axios.post(url, body)
            .then(res => {
                // console.log("charge res:", res)
                this.setState({isLoading: false})
                if(res.data.message === undefined)
                    this.props.onSuccess()
                else
                    toast.error(res.data.message)
            })
            .catch(err => {
                console.error(err)
                this.setState({isLoading: false})
            })
    }

    async submitUpdate() {
        this.setState({isLoading: true, error: ''})
        let stripe_request = await this.props.stripe.createToken({name: "Name"});
        let { token, error } = stripe_request

        if(!token){
            this.setState({ isLoading: false, error: error.message })
            return
        }

        const url = `${config.API_URL}/api/payments/update`
        const { email, uid, displayName } = firebase.auth().currentUser
        const body = {
            "token": token.id,
            "uid": uid,
            "userData": this.props.userData,
            "email": this.state.email,
            "displayName": this.state.name,
            "plan": this.state.selectedPlan
        }
        // axios.post(url, body, { headers: { "Authorization": `Bearer ${token}` }})
        axios.post(url, body)
            .then(res => {
                // console.log("done", res, res.data.statusCode)
                this.setState({isLoading: false})
                if(res.data.statusCode >= 400) {
                    this.setState({error: res.data.message})
                } else {
                    this.setState({updateSuccess: true})
                    this.props.onSuccess()
                }
            })
            .catch(err => {
                console.error(err)
                this.setState({isLoading: false, updateSuccess: true})
            })
    }

    applyCoupon(){
        if(this.state.couponCode.toLowerCase() === 'chad-special-153' || this.state.couponCode === 'chad153'){
            // this.setState({ couponSuccess: true, showCouponModal: false, selectedPlan: '' })
            this.setState({ couponSuccess: true, showCouponModal: false })
            
            //send request to server - wait for response (creates user with 100% discount coupon applied to subscription)
            // this.submitFreeAccount()
            
            //show account
        } else {
            this.setState({ couponSuccess: false, couponError: 'Invalid coupon code.', couponCode: '' })
        }
    }

    async updateUser(){
        try {
            this.setState({isLoading: true})
            await firebase.auth().currentUser.updateEmail(this.state.email)
            await firebase.auth().currentUser.updateProfile({ displayName: this.state.name })
            this.setState({isLoading: false})
        } catch (err) {
            console.error(err)
            this.setState({isLoading: false, error: '', showLoginModal: true})
            return
        }
    }

    

    render() {
        const creationTime = firebase.auth().currentUser.metadata.creationTime;
        const exp = moment(creationTime).add(24, 'hours')
        const now = moment()
        const expiration = exp.format('YYYY-MM-DDTHH:mm:ss')
        

        const TOS = () => {
            let style;
            if(this.state.tosError && this.state.tosChecked !== true)
                style = {
                    marginTop:18, marginLeft:14, display:'inline-block',
                    border: '2px solid red',
                    padding: '3px 10px',
                    borderRadius: '5px'
                }
            else 
                style = {
                    marginTop:18, marginLeft:14, display:'inline-block'
                }
            return (
                <span style={style}>
                    <input type="checkbox" style={{marginRight:10}} checked={this.state.tosChecked} onChange={() => this.setState({tosChecked: !this.state.tosChecked })} />
                    <span>Accept <a href="javascript:;" onClick={() => this.setState({showTosModal: true})}>terms of service</a></span>
                    <TosModal show={this.state.showTosModal} onClose={() => this.setState({showTosModal: false})} />
                </span>
            )
        }
        // console.log("test", expiration)
        // const expiration = `2019-12-24T00:00:00`

        if(this.state.updateSuccess){
            return <Message success>Thank you! Your card has been updated.</Message>
        }

        if(this.props.updateOnly){
            return (
                <React.Fragment>
                    <h3>Card Info</h3>
                    { this.state.error !== '' && 
                        <Message negative>
                            <Message.Header>{this.state.error}</Message.Header>
                        </Message>
                    }
                    <CardElement hidePostalCode={true} />
                    <Button
                        style={{marginTop:20}}
                        color='green'
                        content={'Update'}
                        // icon='dollar'
                        // label={{ basic: true, color: 'green', pointing: 'left', content: 'Update' }}
                        onClick={this.submitUpdate}
                        loading={this.state.isLoading}
                        // floated='right'
                    />
                </React.Fragment>
            )
        }

        if(this.state.plans.length === 0)
            return (
                <Fragment>
                    <h3>Plan</h3>
                    <Segment loading style={{minHeight:100}}></Segment>
                </Fragment>
            )

        const buttonAmount = () => {
            if(qs('trial') === "7day" || this.state.couponCode === 'chad-special-153')
                return "$0"
            else
                return this.state.selectedPlan !== undefined ? this.state.selectedPlan.metadata.button_amount : '$'
        }

        return (
            <>
            <div style={{marginBottom:10}}>
                

                {/* { now < exp && 
                    <div style={{textAlign:'center', marginTop:20}}>
                        <Message success>
                            <Header style={{marginTop:10}}>50% Discount Expires in <Countdown date={expiration} style={{fontSize:24, marginTop:10}} /></Header>
                        </Message>
                    </div>
                } */}
                <h3>
                    Plan
                    <Button floated='right' onClick={() => this.setState({showCouponModal: true})}>
                        {this.state.couponCode !== '' ? this.state.couponCode : 'Coupon Code'}
                    </Button>
                </h3>
                {this.state.planSelectOptions.length > 1 && this.props.planOrder === undefined && 
                    _.map(_.filter(_.sortBy(this.state.plans, ['metadata.order']), plan => plan.metadata.status === 'publish'), plan => (
                        <Segment key={plan.id}>
                            <span style={{marginRight: 20}}>
                                <label>
                                    <input type="radio"
                                        checked={this.state.selectedPlanKey === plan.id} 
                                        onChange={() => this.setState({selectedPlan: plan, selectedPlanKey: plan.id})} 
                                        style={{marginRight:10}} 
                                        />
                                    {plan.metadata.pre_purchase_description !== undefined ? plan.metadata.pre_purchase_description : plan.metadata.description}
                                </label>
                            </span>
                        </Segment>
                    ))
                }
                {this.state.planSelectOptions > 1 && this.props.planOrder !== undefined && this.state.selectedPlan !== '' &&
                    // <strong>{this.state.selectedPlan.metadata.pre_purchase_description !== undefined ? this.state.selectedPlan.metadata.pre_purchase_description : this.state.selectedPlan.metadata.description}</strong>
                    <Dropdown
                        placeholder='Select Plan'
                        fluid
                        selection
                        options={this.state.planSelectOptions}
                        value={this.state.selectedPlanKey}
                        onChange={(event, data) => {
                            // console.log("selected plan", this.state.plans)
                            this.setState({selectedPlan: _.find(this.state.plans, {id: data.value}), selectedPlanKey: data.value})
                        }}
                    />
                }

                {this.state.planSelectOptions.length === 1 &&
                    <div>{this.state.planSelectOptions[0].text}</div>
                }
                
                <Modal open={this.state.showCouponModal} centered={false} size='mini' closeIcon onClose={() => this.setState({ showCouponModal: false })}>
                    <Modal.Header>Coupon Code</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {this.state.couponError !== '' && 
                                <Message negative>
                                    <Message.Header>{this.state.couponError}</Message.Header>
                                </Message>
                            }
                            <p>Enter a coupon code</p>
                            <Input fluid onChange={event => this.setState({couponCode: event.target.value})} value={this.state.couponCode}/>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={this.applyCoupon} positive icon='checkmark' labelPosition='right' content='Apply' />
                    </Modal.Actions>
                </Modal>

                <Modal open={this.state.showAlert} centered={true} size='small' closeIcon onClose={() => this.setState({ showAlert: false })}>
                    <Modal.Content>
                        <Modal.Description>
                            {this.state.alertText}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => this.setState({showAlert: false})} content='Close' />
                    </Modal.Actions>
                </Modal>
                

            </div>
            { this.state.error !== '' && 
                <Message negative>
                    <Message.Header>{this.state.error}</Message.Header>
                </Message>
            }

            {this.state.selectedPlan !== '' && this.state.selectedPlan !== undefined && 
                <React.Fragment>
                    <h3>Card Info</h3>
                    {qs('trial') === "7day" &&
                        <Message info>
                            <Message.Header>7 Day FREE Trial</Message.Header>
                            <p>This is a 7 day free trial. You will not be charged for 7 days, and you can cancel anytime before then for no charge.</p>
                        </Message>
                    }
                    
                    <CardElement hidePostalCode={true} />
                    <TOS />
                    <Button
                        disabled={this.state.selectedPlan === '' || this.state.isLoading}
                        style={{marginTop:20}}
                        color='green'
                        content={buttonAmount()}
                        // icon='dollar'
                        label={{ basic: true, color: 'green', pointing: 'left', content: 'Subscribe' }}
                        onClick={this.submitPayment}
                        loading={this.state.isLoading}
                        floated='right'
                    />
                    {/* <div style={{ clear:'both', textAlign:'center', marginTop:46, marginBottom: -25}}><small>*This is a proprietary system. Payments are non-refundable.</small></div> */}
                </React.Fragment>
            }
            </>
        )
    }
}

export default injectStripe(Payment);









